import { AppState } from '../../core/core.state';
import { Dropzone } from '../../common/models/dropzone.model';
import { GpsLocation } from '../../common/models/gps-location.model';
import { createFeatureSelector } from '@ngrx/store';
import { DropzonesMapMarkerView } from './models/dropzones-map-view.model';

export const selectDropzonesState = createFeatureSelector<State, DropzonesState>('dropzones');

export interface DropzonesState {
    items: Dropzone[];
    mapMarkers: DropzonesMapMarkerView[];
    userNear: Dropzone[];
}

export interface State extends AppState {
    dropzones: DropzonesState;
}
