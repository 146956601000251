import { AuthState, User } from './auth.models';
import { authGetUserSuccess, authInitCompleted, authLogin, authLoginSuccess, authLogout, authRefreshTokenSuccess, authUserUpdateSuccess } from './auth.actions';
import { createReducer, on, Action } from '@ngrx/store';

export const initialState: AuthState = {
    isAuthenticated: false,
    user: null
};

const reducer = createReducer(
    initialState,
    on(authGetUserSuccess, (state, action) => ({ ...state, user: action.user })),
    on(authUserUpdateSuccess, (state, action) => ({ ...state, user: action.user })),
    on(authInitCompleted, (state, action) => ({ ...state, isAuthenticated: action.isAuthenticated })),
    on(authLogout, (state) => ({ ...state, isAuthenticated: false }))
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
    return reducer(state, action);
}
