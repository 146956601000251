import { Component, OnInit, ChangeDetectionStrategy, HostListener, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable, takeUntil } from 'rxjs';
import { BaseComponent } from '../../core/base.component';
import { actionNetworkStartOffline, actionNetworkStopOffline } from '../../core/network/network.actions';

@Component({
    selector: 'ask-for-offline-dialog',
    templateUrl: './ask-for-offline-dialog.component.html',
    styleUrls: ['./ask-for-offline-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AskForOfflineDialogComponent extends BaseComponent implements OnInit {
    public url: string;

    constructor(
        store: Store,
        private actions$: Actions,
        public dialogRef: MatDialogRef<AskForOfflineDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {}
    ) {
        super(store);
        this.actions$.pipe(ofType(actionNetworkStopOffline), takeUntil(this.destroy$)).subscribe((result) => {
            // close when online but dialog shown
            if (this.dialogRef != null && this.dialogRef.getState() == MatDialogState.OPEN) {
                this.dialogRef.close();
            }
        });
    }

    ngOnInit(): void {}

    public static Show(url: string, dialog: MatDialog): MatDialogRef<AskForOfflineDialogComponent> {
        let data: any = { url: url };
        return AskForOfflineDialogComponent.ShowDialog(data, dialog);
    }

    public switchToOffline(): void {
        this.store.dispatch(actionNetworkStartOffline());
        this.dialogRef.close();
    }

    private static ShowDialog(data: any, dialog: MatDialog): MatDialogRef<AskForOfflineDialogComponent> {
        const dialogRef = dialog.open(AskForOfflineDialogComponent, {
            maxWidth: '360px',
            maxHeight: '200px',
            height: '200px',
            width: '360px',
            data: data,
            panelClass: 'custom-dialog-container'
        });
        return dialogRef;
    }
}
