import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf, ErrorHandler, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { ReducerManager, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';

import { AppState, reducers, metaReducers, selectRouterState } from './core.state';
import { AuthEffects } from './auth/auth.effects';
import { selectIsAuthenticated, selectAuth } from './auth/auth.selectors';
import { authLogin, authLogout } from './auth/auth.actions';
import { authRegister } from './auth/auth.actions';
import { AuthGuardService } from './auth/auth-guard.service';
import { TitleService } from './title/title.service';
import { ROUTE_ANIMATIONS_ELEMENTS, routeAnimations } from './animations/route.animations';
import { AnimationsService } from './animations/animations.service';
import { AppErrorHandler } from './error-handler/app-error-handler.service';
import { CustomSerializer } from './router/custom-serializer';
import { LocalStorageService } from './local-storage/local-storage.service';
import { HttpErrorInterceptor } from './http-interceptors/http-error.interceptor';
import { NotificationService } from './notifications/notification.service';
import { SettingsEffects } from './settings/settings.effects';
import { selectSettingsLanguage, selectEffectiveTheme, selectSettingsStickyHeader } from './settings/settings.selectors';
import { MatButtonModule } from '@angular/material/button';
import { faCog, faBars, faRocket, faPowerOff, faUserCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faMediumM, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { TokenStorageService } from './auth/token-storage.service';
import { AuthenticationService } from './auth/authentication.service';
import { AuthModule, AUTH_SERVICE, PROTECTED_FALLBACK_PAGE_URI, PUBLIC_FALLBACK_PAGE_URI } from 'ngx-auth';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InitEffects } from './init/init.effects';
import { NetworkEffects } from './network/network.effects';
import { ActivitiesService } from '../features/activities/activities.service';
import { MapsOfflineService } from '../features/maps/maps-offline.service';
import { NgrxStoreIdbModule } from './ngrx-store-idb/ngrx-store-idb.module';

export {
    TitleService,
    selectAuth,
    authLogin,
    authLogout,
    authRegister,
    routeAnimations,
    AppState,
    LocalStorageService,
    selectIsAuthenticated,
    ROUTE_ANIMATIONS_ELEMENTS,
    AnimationsService,
    AuthGuardService,
    selectRouterState,
    NotificationService,
    selectEffectiveTheme,
    selectSettingsLanguage,
    selectSettingsStickyHeader
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, `${environment.i18nPrefix}/assets/i18n/`, '.json');
}

export function factory(authenticationService: AuthenticationService) {
    return authenticationService;
}

@NgModule({
    imports: [
        // angular
        AuthModule,

        CommonModule,
        HttpClientModule,
        FormsModule,

        // material
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatSelectModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatButtonModule,
        FlexLayoutModule,

        // ngrx
        NgrxStoreIdbModule.forRoot({ keys: ['activities'], debugInfo: false, idb: { dbName: 'db', storeName: 'store' } }),
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreRouterConnectingModule.forRoot(),
        EffectsModule.forRoot([InitEffects, AuthEffects, SettingsEffects, NetworkEffects]),
        environment.production
            ? []
            : StoreDevtoolsModule.instrument({
                  name: 'ZmiecSmiecWeb'
              }),

        // 3rd party
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [],
    providers: [
        TokenStorageService,
        AuthenticationService,
        ActivitiesService,
        MapsOfflineService,

        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        { provide: RouterStateSerializer, useClass: CustomSerializer },
        { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
        { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
        {
            provide: AUTH_SERVICE,
            deps: [AuthenticationService],
            useFactory: factory
        }
    ],
    exports: [
        // angular
        FormsModule,

        // material
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatSelectModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatButtonModule,
        FlexLayoutModule,
        // 3rd party
        FontAwesomeModule,
        TranslateModule
    ]
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule,
        faIconLibrary: FaIconLibrary
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
        faIconLibrary.addIcons(faCog, faBars, faRocket, faPowerOff, faUserCircle, faPlayCircle, faGithub, faMediumM, faTwitter, faInstagram, faYoutube);
    }
}
