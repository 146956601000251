<mat-dialog-content  style="overflow: hidden;" fxLayout="column" fxLayoutAlign="center center">

    <div fxFlex="100" fxLayoutAlign="center center">
        <img width="40%" class="mt-4 mb-4 img-responsive" src='assets/img/gps_permissions.png' />
    </div>
    <div fxFlex="100" fxLayoutAlign="center center" class="mb-3 text-center">
        Do poprawnego funkcjonowania potrzebujemy dostępu do Twojej dokładnej lokalizacji. <br><br>
        Kliknij ZGODA i wybierz dokładną lokalizację, a nie przybliżoną.
    </div>    


</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Anuluj</button>
    <button mat-flat-button color="primary" (click)="allow()">Zgoda</button>
</mat-dialog-actions>