import { createSelector } from '@ngrx/store';
import { AreasState, selectAreasState } from '../areas.state';

export const selectAreas = createSelector(selectAreasState, (state: AreasState) => state?.areas);
export const selectAreasByDistance = createSelector(selectAreasState, (state: AreasState) => state?.areasByDistance);
export const selectAreasNearest = createSelector(selectAreasState, (state: AreasState) => state?.nearest);
export const selectAreasByFavorites = createSelector(selectAreasState, (state: AreasState) => state?.areas.filter((area) => state.favorites.includes(area.id)));
export const selectAreasSelectedArea = createSelector(selectAreasState, (state: AreasState) => state.selectedArea);

export const selectAreasUserInside = createSelector(selectAreasState, (state: AreasState) => state.userInside);

export const selectAreasMapsDownloadProgress = createSelector(selectAreasState, (state: AreasState) => state.mapsDownloadProgress);
export const selectAreasMapsDownloadInProgress = createSelector(selectAreasState, (state: AreasState) => state.mapsDownloadInProgress);

export const selectAreasFavorites = createSelector(selectAreasState, (state: AreasState) => state.favorites);
