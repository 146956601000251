import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { AppState } from '../core/core.module';
import { AppSettings } from './models/app-settings.model';
import { Area } from './models/area.model';
import { GpsLocation } from './models/gps-location.model';

export const FEATURE_NAME = 'common';
export const selectCommonState = createFeatureSelector<State, CommonState>(FEATURE_NAME);

export interface CommonState {
    userLocation: GpsLocation;
    gpsReady: boolean;
    gpsLowAccuracyCounter: number;
    gpsLowAccuracy: boolean;
    appSettings: AppSettings;
}

export interface State extends AppState {
    common: CommonState;
}
