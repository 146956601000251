import { ActionReducer } from '@ngrx/store';

import { AppState } from '../core.state';

export function debug(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return function (state, action) {
        const newState = reducer(state, action);

        if (!action.type.includes('@ngrx/store-devtools/') && !action.type.includes('@ngrx/store/update-reducers'))
            console.log(`ACTION: ${action.type}`, {
                payload: <any>action,
                oldState: state,
                newState
            });
        return newState;
    };
}
