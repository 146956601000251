import { Injectable } from '@angular/core';

const APP_PREFIX = 'ZMIECSMIEC-';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() {}

    static loadInitialState() {
        return Object.keys(localStorage).reduce((state: any, storageKey) => {
            if (storageKey.includes(APP_PREFIX)) {
                const stateKeys = storageKey
                    .replace(APP_PREFIX, '')
                    .toLowerCase()
                    .split('.')
                    .map((key) =>
                        key
                            .split('-')
                            .map((token, index) => (index === 0 ? token : token.charAt(0).toUpperCase() + token.slice(1)))
                            .join('')
                    );
                let currentStateRef = state;
                stateKeys.forEach((key, index) => {
                    if (index === stateKeys.length - 1) {
                        currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey));
                        return;
                    }
                    currentStateRef[key] = currentStateRef[key] || {};
                    currentStateRef = currentStateRef[key];
                });
            }
            return state;
        }, {});
    }

    setItem(key: string, value: any) {
        localStorage.setItem(`${APP_PREFIX}${key}`, value);
    }

    getItem(key: string) {
        return localStorage.getItem(`${APP_PREFIX}${key}`);
    }

    removeItem(key: string) {
        localStorage.removeItem(`${APP_PREFIX}${key}`);
    }

    public getBoolan(key: string): boolean {
        if (this.getItem(key) == '1') return true;
        return false;
    }

    public setBoolean(key: string, value: boolean): void {
        if (value) this.setItem(key, '1');
        else this.setItem(key, '0');
    }

    /** Tests that localStorage exists, can be written to, and read from. */
    testLocalStorage() {
        const testValue = 'testValue';
        const testKey = 'testKey';
        let retrievedValue: string;
        const errorMessage = 'localStorage did not return expected value';

        this.setItem(testKey, testValue);
        retrievedValue = this.getItem(testKey);
        this.removeItem(testKey);

        if (retrievedValue !== testValue) {
            throw new Error(errorMessage);
        }
    }
}
