import { Component, OnInit, ChangeDetectionStrategy, HostListener, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { Activity } from '../../common/models/activity.model';

@Component({
    selector: 'social-share-dialog',
    templateUrl: './social-share-dialog.component.html',
    styleUrls: ['./social-share-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialShareDialogComponent implements OnInit {
    public url: string;

    constructor(public dialogRef: MatDialogRef<SocialShareDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { url: string }) {
        this.url = data.url;
    }

    ngOnInit(): void {}

    public static ShowForActivity(activity: Activity, image: number, dialog: MatDialog): MatDialogRef<SocialShareDialogComponent> {
        const id = activity.id;
        const code = activity.share_code;

        let data: any = { url: 'https://share.zmiecsmiec.pl/share/activity/' + id + '/' + code + '/' + image };
        return SocialShareDialogComponent.ShowDialog(data, dialog);
    }

    public static Show(url: string, dialog: MatDialog): MatDialogRef<SocialShareDialogComponent> {
        let data: any = { url: url };
        return SocialShareDialogComponent.ShowDialog(data, dialog);
    }

    private static ShowDialog(data: any, dialog: MatDialog): MatDialogRef<SocialShareDialogComponent> {
        const dialogRef = dialog.open(SocialShareDialogComponent, {
            maxWidth: '360px',
            maxHeight: '200px',
            height: '200px',
            width: '360px',
            data: data,
            panelClass: 'custom-dialog-container'
        });
        return dialogRef;
    }
}
