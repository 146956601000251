import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions, ROOT_EFFECTS_INIT, OnInitEffects } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppCoreService } from '../../common/services/app-core.service';
import { MainApiService } from '../../common/services/main-api.service';
import { authAnonymousLogin, authInitCompleted, authLoginSuccess } from '../../core/auth/auth.actions';
import { PwaInstallService } from '../pwa/pwa-install.service';

import {
    actionCoreInit,
    actionCoreInitCompleted,
    actionCoreInitGetInstanceId,
    actionCoreInitGetInstanceIdSuccess,
    actionCoreInitInstall,
    actionCoreIsLoading,
    actionCoreIsNotInitialized
} from './init.actions';
import { selectInitRedirectAfterInitUrl } from './init.selectors';

@Injectable()
export class InitEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private coreService: AppCoreService,
        private apiService: MainApiService,
        private installService: PwaInstallService
    ) {}

    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            map((action) => actionCoreInit())
        )
    );

    loadingAfterNavigation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerNavigationAction),
            map((action) => actionCoreIsLoading({ value: true }))
        )
    );

    initCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authInitCompleted),
            map((action) => actionCoreInitCompleted())
        )
    );

    getInstanceId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCoreInitGetInstanceId),
            exhaustMap((action) => {
                return this.coreService.getInstanceId().pipe(
                    map((id) => {
                        return actionCoreInitGetInstanceIdSuccess({ id: id });
                    })
                );
            })
        )
    );

    installApp$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionCoreInitInstall),
                map(() => {
                    this.installService.tryToInstall();
                })
            ),
        { dispatch: false }
    );

    // isNotInitializedRedirect$ = createEffect(
    //     () =>
    //         this.actions$.pipe(
    //             ofType(actionCoreIsNotInitialized),
    //             tap(() => this.router.navigate(['/']))
    //         ),
    //     { dispatch: false }
    // );

    // redirectAfterLogin$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(authAuthCompleted),
    //         map(() => actionCoreInitCompleted())
    //     )
    // );
}
