import { Action, createReducer, on } from '@ngrx/store';
import { CommonState } from '../common.state';
import { PlayerLocationService } from '../services/player-location.service';
import { actionCommonAppSettingsGetSuccess, actionCommonGpsError, actionCommonLocationLowAccuracy, actionCommonSetPlayerLocation } from './common.actions';

export const initialState: CommonState = {
    userLocation: null,
    gpsReady: false,
    appSettings: null,
    gpsLowAccuracyCounter: 0,
    gpsLowAccuracy: false
};

const reducer = createReducer(
    initialState,

    on(actionCommonSetPlayerLocation, (state, { location }) => {
        let gpsLowAccuracyCounter = state.gpsLowAccuracyCounter;
        let gpsLowAccuracy = state.gpsLowAccuracy;
        if (location.accuracy > PlayerLocationService.AccuracyTreshold) {
            gpsLowAccuracyCounter++;
        } else {
            gpsLowAccuracyCounter = 0;
            gpsLowAccuracy = false;
        }
        return {
            ...state,
            userLocation: location,
            gpsReady: true,
            gpsLowAccuracy: gpsLowAccuracy,
            gpsLowAccuracyCounter: gpsLowAccuracyCounter
        };
    }),

    on(actionCommonLocationLowAccuracy, (state, { accuracy }) => ({
        ...state,
        gpsLowAccuracy: true,
        gpsLowAccuracyCounter: 0
    })),

    on(actionCommonGpsError, (state, { error }) => ({
        ...state,
        gpsReady: false
    })),

    on(actionCommonAppSettingsGetSuccess, (state, { settings }) => ({
        ...state,
        appSettings: settings
    }))
);

export function commonReducer(state: CommonState | undefined, action: Action) {
    return reducer(state, action);
}
