import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions, ROOT_EFFECTS_INIT, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { authAnonymousLogin, authLoginSuccess } from '../../core/auth/auth.actions';
import { actionCoreInitCompleted } from '../../core/init/init.actions';
import { selectCommonState } from '../common.state';
import { AppCoreService } from '../services/app-core.service';
import { MainApiService } from '../services/main-api.service';
import { PlayerLocationService } from '../services/player-location.service';
import {
    actionCommonAppIsForeground,
    actionCommonAppSettingsGet,
    actionCommonAppSettingsGetFailure,
    actionCommonAppSettingsGetSuccess,
    actionCommonInitPlayerLocation,
    actionCommonLocationLowAccuracy,
    actionCommonLocationPermissionPrompt,
    actionCommonSetPlayerLocation
} from './common.actions';

@Injectable()
export class CommonEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private coreService: AppCoreService,
        private apiService: MainApiService,
        private playerLocationService: PlayerLocationService
    ) {}

    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCoreInitCompleted),
            switchMap((action) => [actionCommonInitPlayerLocation(), actionCommonAppSettingsGet()])
        )
    );

    initPlayerLocation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionCommonInitPlayerLocation),
                map((action) => {
                    this.playerLocationService.init();
                    this.playerLocationService.startWatch();
                })
            ),
        { dispatch: false }
    );

    locationPrompt$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionCommonLocationPermissionPrompt),
                map((action) => {
                    this.playerLocationService.getCurrentLocation();
                })
            ),
        { dispatch: false }
    );

    backtoForeground$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionCommonAppIsForeground),
                map((action) => {
                    this.playerLocationService.startWatch();
                })
            ),
        { dispatch: false }
    );

    lowAccuracyLocation$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionCommonSetPlayerLocation),
                withLatestFrom(this.store.select(selectCommonState)),
                map(([action, commonState]) => {
                    if (
                        action.location.accuracy > PlayerLocationService.AccuracyTreshold &&
                        commonState.gpsLowAccuracyCounter > PlayerLocationService.AccuracyWarningCount
                    ) {
                        this.store.dispatch(actionCommonLocationLowAccuracy({ accuracy: action.location.accuracy }));
                    }
                })
            ),
        { dispatch: false }
    );

    getSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCommonAppSettingsGet),
            switchMap((action) =>
                this.apiService.getAppSettings().pipe(
                    map((settings) => {
                        return actionCommonAppSettingsGetSuccess({ settings: settings });
                    }),
                    catchError((error) => of(actionCommonAppSettingsGetFailure({ error: error })))
                )
            )
        )
    );
}
