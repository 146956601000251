import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MainApiService } from '../../../common/services/main-api.service';
import { MapUtilsService } from '../../../common/services/map-utils.service';
import { actionCommonSetPlayerLocation } from '../../../common/store/common.actions';
import { actionCoreInitCompleted, actionCoreIsLoading } from '../../../core/init/init.actions';
import {
    actionDropzonesCreate,
    actionDropzonesCreateFailure,
    actionDropzonesCreateSuccess,
    actionDropzonesSearch,
    actionDropzonesSearchFailure,
    actionDropzonesSearchSuccess,
    actionDropzonesSetUserNearDropzones
} from './dropzones.actions';
import { selectDropzones } from './dropzones.selectors';
import { selectUser } from '../../../core/auth/auth.selectors';

@Injectable()
export class DropzonesEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private apiService: MainApiService,
        private mapUtilsService: MapUtilsService
    ) {}

    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCoreInitCompleted),
            map((action) => actionDropzonesSearch())
        )
    );

    checkAreasUserIn = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCommonSetPlayerLocation),
            withLatestFrom(this.store.pipe(select(selectDropzones))),
            map(([action, dropzones]) => {
                const dropzoneUserNear = this.mapUtilsService.getDropzonesUserNear(dropzones, action.location);
                return actionDropzonesSetUserNearDropzones({ dropzones: dropzoneUserNear });
            })
        )
    );

    createDropzone = createEffect(() =>
        this.actions$.pipe(
            ofType(actionDropzonesCreate),
            withLatestFrom(this.store.pipe(select(selectUser))),
            switchMap(([action, user]) =>
                this.apiService.createDropzone(action.dropzone, action.picture, action.area).pipe(
                    map((dropzone) => {
                        return actionDropzonesCreateSuccess({ dropzone: dropzone });
                    }),
                    catchError((error) => of(actionDropzonesCreateFailure({ error: error })))
                )
            )
        )
    );

    afterDropzoneCreate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionDropzonesCreate),
            map((action) => actionDropzonesSearch())
        )
    );

    searchDropzones = createEffect(() =>
        this.actions$.pipe(
            ofType(actionDropzonesSearch),
            switchMap((action) =>
                this.apiService.getDropzones().pipe(
                    map((dropzones) => {
                        return actionDropzonesSearchSuccess({ dropzones: dropzones });
                    }),
                    catchError((error) => of(actionDropzonesSearchFailure({ error: error })))
                )
            )
        )
    );
}
