<div [class]="'theme-wrapper ' + (theme$ | async)">

  <mat-sidenav-container>

    <mat-sidenav #sidenav mode="over">
      <div class="branding"><img src="assets/logo.png"/>
      </div>
      <mat-nav-list class="mt-2">
        <a mat-list-item *ngFor="let item of navigationSideMenu" (click)="sidenav.close()" [routerLink]="[item.link]"
          routerLinkActive="active">
          <mat-icon class="mr-2">{{item.icon}}</mat-icon>

          {{item.label | translate}}
        </a>

        <mat-divider class="mt-2 mb-2"></mat-divider>

        <a *ngIf="(isAuthenticated$ | async)" mat-list-item (click)="onLogoutClick()">
          <mat-icon class="mr-2">exit_to_app</mat-icon>
          Wyloguj się
        </a>

        <a *ngIf="!(isAuthenticated$ | async)" mat-list-item (click)="onLogoutClick()">
          <mat-icon class="mr-2">login</mat-icon>
          Zaloguj się
        </a>
      </mat-nav-list>

      <div *ngIf="(isInstallable$ | async)" class="banner p-2 mt-4" fxLayout="column">
        <div fxLayout="row" class="mb-3">
          <mat-icon class="icon m-1 mt-2" [inline]="true">pets</mat-icon>
          <div>
            Zostaw ślad a łatwiej znajdziesz aplikację...
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="end start">
          <button (click)="installApplication()" mat-flat-button color="link">Zainstaluj</button>
        </div>
      </div>

    </mat-sidenav>

    <div class="wrapper">

      <div *ngIf="(isHeaderVisible$ | async)" class="toolbar" [style.position]="(stickyHeader$ | async) ? 'fixed' : 'inherit'" [class.mat-elevation-z4]="(stickyHeader$ | async)">
        <mat-toolbar color="primary">
          <button style="color:white" mat-icon-button class="" (click)="sidenav.open()">
            <fa-icon icon="bars"></fa-icon>
          </button>

          <span class="branding spacer center"><img class="image-responsive" src="assets/app_logo_white_no_icon.png" /></span>
          <img *ngIf="(isOfflineNetwork$ | async)" src="assets/img/no-internet.png" height="32" />

          <button mat-icon-button [routerLink]="['/help']" style="color:white" class="mr-2"><mat-icon>help</mat-icon></button>

          <button routerLinkActive="hidden" mat-button mat-stroked-button class="primary-green-background" *ngIf="!(isAuthenticated$ | async)" routerLink="/login">
            {{ 'anms.menu.login' | translate }}
          </button>

          <button *ngIf="(isAuthenticated$ | async)"
                  mat-icon-button
                  [matMenuTriggerFor]="toolbarUserMenu">
            <img [src]="(user$ | async)?.avatar_url"  width="48" class="image-responsive image-avatar" />
          </button>

          <mat-menu #toolbarUserMenu="matMenu">
            <button mat-menu-item [routerLink]="['/profile']">
              <mat-icon>person</mat-icon>
              <span>Profil</span>
            </button>

            <button  mat-menu-item (click)="onLogoutClick()">
              <mat-icon>logout</mat-icon>
              <span>{{ 'anms.menu.logout' | translate }}</span>
            </button>
          </mat-menu>
        </mat-toolbar>
      </div>

      <div class="content" [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.data && o.activatedRoute.routeConfig.data.title">
      	<ngx-spinner [bdColor]="'#ffffff44'" [fullScreen]="false" type="ball-scale-multiple"  size="large">
				</ngx-spinner>
        <div *ngIf="isLoading">
          <div fxLayoutAlign="center center" class="mt-5 mb-2" style="z-index: 10000;">
            <img fxFlex="60" class="img-responsive" src="assets/img/screens/splash.png" />
          </div>
        </div>
        <router-outlet #o="outlet"></router-outlet>
      </div>

    </div>

  </mat-sidenav-container>

</div>
