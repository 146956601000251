<h2 mat-dialog-title>Podziel się...</h2>
<mat-dialog-content style="overflow: hidden;" fxLayoutAlign="center center">

    <share-buttons [theme]="'default'"
        [include]="['facebook','twitter','linkedin','messenger', 'pinterest']"
        [show]="5"
        [url]="url"
        [autoSetMeta]="false"
></share-buttons>
    
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>ZAMKNIJ</button>
</mat-dialog-actions>