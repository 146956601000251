import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SoundService {
    private fanfare: HTMLAudioElement;

    constructor() {
        this.fanfare = new Audio();
        this.fanfare.src = '/assets/audio/fanfare.wav';
        this.fanfare.load();
    }

    public playFanfare(): void {
        this.fanfare.play();
    }
}
