import { Action, createReducer, on } from '@ngrx/store';
import { actionCoreInitCompleted, actionCoreInitSetIsInstallable, actionCoreInitSetIsInstalled, actionCoreIsLoading } from './init.actions';
import { InitState } from './init.state';

export const initialState: InitState = {
    isInitialized: false,
    instanceId: null,
    redirectAfterInitUrl: '/',
    isInstalled: false,
    isInstallable: false,
    isLoading: false
};

const reducer = createReducer(
    initialState,
    on(actionCoreInitCompleted, (state) => ({ ...state, isInitialized: true })),
    on(actionCoreInitSetIsInstallable, (state, { value }) => ({ ...state, isInstallable: value })),
    on(actionCoreInitSetIsInstalled, (state, { value }) => ({ ...state, isInstalled: value })),
    on(actionCoreIsLoading, (state, { value }) => ({ ...state, isLoading: value }))
);

export function initReducer(state: InitState | undefined, action: Action): InitState {
    return reducer(state, action);
}
