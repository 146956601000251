import { createAction, props } from '@ngrx/store';
import { Dropzone } from '../../../common/models/dropzone.model';
import { GpsLocation } from '../../../common/models/gps-location.model';
import { DropzonesMapMarkerView } from '../models/dropzones-map-view.model';
import { Area } from '../../../common/models/area.model';

export const actionDropzonesSearch = createAction('[Dropzones] Search');
export const actionDropzonesSearchSuccess = createAction('[Dropzones] Search Success', props<{ dropzones: Dropzone[] }>());
export const actionDropzonesSearchFailure = createAction('[Dropzones] Search Failure', props<{ error: string }>());

export const actionDropzonesSetMapMarkers = createAction('[Dropzones] Set Map Markers', props<{ markers: DropzonesMapMarkerView[] }>());

export const actionDropzonesCreate = createAction('[Dropzones] Create', props<{ dropzone: Dropzone; picture: string; area: Area }>());
export const actionDropzonesCreateSuccess = createAction('[Dropzones] Create Success', props<{ dropzone: Dropzone }>());
export const actionDropzonesCreateFailure = createAction('[Dropzones] Create Failure', props<{ error: string }>());

// MAP
export const actionDropzonesCenterPlayer = createAction('[Dropzones] Map Center Player');
export const actionDropzonesFitBounds = createAction('[Dropzones] Map Fit Bounds');

export const actionDropzonesSetUserNearDropzones = createAction('[Dropzones] Set User Near', props<{ dropzones: Dropzone[] }>());
