import { Action, ActionReducer, createReducer, INIT, on, ReducerManager, UPDATE } from '@ngrx/store';
import { ActivityItem } from '../../../common/models/activity-item.model';
import { Activity } from '../../../common/models/activity.model';
import { Notification } from '../../../common/models/notification.model';
import { AppState } from '../../../core/core.state';
import { ActivitiesState } from '../activities.state';
import {
    actionActivitiesActivityUploadSuccess,
    actionActivitiesCreateSuccess,
    actionActivitiesDownloadSuccess,
    actionActivitiesFinish,
    actionActivitiesFinishSuccess,
    actionActivitiesGetCurrentSuccess,
    actionActivitiesItemCreateSuccess,
    actionActivitiesSetCurrent,
    actionActivitiesSyncStart,
    actionActivitiesActivityItemUploadSuccess,
    actionActivitiesUpdateUserInsideCurrentAreaSuccess,
    actionActivitiesDirtyChange,
    actionActivitiesNotificationCreateSuccess,
    actionActivitiesNotificationUploadSuccess
} from './activities.actions';

export const initialState: ActivitiesState = {
    items: [],
    syncActive: false,
    hasLocalData: false,
    isUserInsideCurrentArea: false,
    lastPositionSaveTime: new Date().setDate(new Date().getDate() - 1),
    notifications: []
};

function updateItemsArray(items: Activity[], search: Activity, newValue: Activity): Activity[] {
    let index = -1;

    if (search.id > 0) {
        index = items.findIndex((item) => item.id == search.id);
    } else if (search.guid) {
        index = items.findIndex((item) => item.guid == search.guid);
    }
    let newItems: Activity[] = [...items];
    newItems[index] = newValue;
    return newItems;
}
function updateNotificationsArray(items: Notification[], search: Notification): Notification[] {
    if (search.id > 0) {
        return items.filter((item) => item.id != search.id);
    } else if (search.guid) {
        return items.filter((item) => item.guid != search.guid);
    }
}

const reducer: ActionReducer<ActivitiesState, Action> = createReducer(
    initialState,

    on(actionActivitiesUpdateUserInsideCurrentAreaSuccess, (state, { value }) => {
        return {
            ...state,
            isUserInsideCurrentArea: value
        };
    }),
    on(actionActivitiesCreateSuccess, (state, { activity, startItem }) => {
        let newActivityItem = { ...startItem, dirty: true };
        return {
            ...state,
            hasLocalData: true,
            items: [...state.items, { ...activity, dirty: true, items: [...activity.items, newActivityItem] }]
        };
    }),
    on(actionActivitiesItemCreateSuccess, (state, { activity, item }) => {
        let updatedItems = [];
        if (!activity.items) {
            updatedItems = updateItemsArray(state.items, activity, { ...activity, dirty: true, items: [{ ...item, dirty: true }] });
        } else {
            updatedItems = updateItemsArray(state.items, activity, { ...activity, dirty: true, items: [...activity.items, { ...item, dirty: true }] });
        }
        return {
            ...state,
            hasLocalData: true,
            items: updatedItems,
            lastPositionSaveTime: Date.now()
        };
    }),
    on(actionActivitiesFinishSuccess, (state, { activity, finishItem }) => {
        let updatedItems = [];
        if (!activity.items) {
            updatedItems = updateItemsArray(state.items, activity, { ...activity, dirty: true, items: [{ ...finishItem, dirty: true }] });
        } else {
            updatedItems = updateItemsArray(state.items, activity, { ...activity, dirty: true, items: [...activity.items, { ...finishItem, dirty: true }] });
        }

        return {
            ...state,
            hasLocalData: true,
            items: updatedItems
        };
    }),
    on(actionActivitiesNotificationCreateSuccess, (state, { notification }) => {
        return {
            ...state,
            hasLocalData: true,
            notifications: [...state.notifications, notification]
        };
    }),
    on(actionActivitiesDirtyChange, (state, { isDirty }) => {
        return {
            ...state,
            hasLocalData: isDirty
        };
    }),
    on(actionActivitiesDownloadSuccess, (state, { items }) => {
        return {
            ...state,
            items: items
        };
    }),
    on(actionActivitiesActivityUploadSuccess, (state, { activity, server_id }) => {
        let updatedItems = updateItemsArray(state.items, activity, {
            ...activity,
            id: server_id,
            dirty: false,
            guid: null,
            items: activity.items.map((item) => {
                return { ...item, activity_id: server_id };
            })
        });

        return {
            ...state,
            items: updatedItems
        };
    }),
    on(actionActivitiesActivityItemUploadSuccess, (state, { activity, item, server_id }) => {
        let updatedItems = updateItemsArray(state.items, activity, {
            ...activity,
            items: activity.items.filter((i) => i != item)
        });
        return {
            ...state,
            items: updatedItems
        };
    }),
    on(actionActivitiesNotificationUploadSuccess, (state, { notification, server_id }) => {
        let updatedNotifications = updateNotificationsArray(state.notifications, notification);

        return {
            ...state,
            notifications: updatedNotifications
        };
    })
);

export function activitiesReducer(state: ActivitiesState | undefined, action: Action) {
    return reducer(state, action);
}
