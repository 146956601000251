import { AppState } from '../../core/core.state';
import { createFeatureSelector } from '@ngrx/store';
import { Area } from '../../common/models/area.model';

export const selectAreasState = createFeatureSelector<State, AreasState>('areas');

export interface AreasState {
    areas: Area[];
    areasByDistance: Area[];
    selectedArea: Area;
    userInside: Area[];
    mapsDownloadInProgress: boolean;
    mapsDownloadProgress: number;
    favorites: number[];
    nearest: Area;
}

export interface State extends AppState {
    areas: AreasState;
}
