import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

import { environment as env } from '../../environments/environment';

import {
    authLogout,
    routeAnimations,
    LocalStorageService,
    selectIsAuthenticated,
    selectSettingsStickyHeader,
    selectSettingsLanguage,
    selectEffectiveTheme,
    NotificationService
} from '../core/core.module';
import { actionSettingsChangeAnimationsPageDisabled, actionSettingsChangeLanguage } from '../core/settings/settings.actions';
import { selectSettingsHeaderVisible } from '../core/settings/settings.selectors';
import { actionCoreInitInstall, actionCoreInitShowiOSInstallPrompt } from '../core/init/init.actions';
import { PwaInstallService } from '../core/pwa/pwa-install.service';
import { selectInitIsInstallable, selectInitIsLoading } from '../core/init/init.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { IosInstallPromptComponent } from '../shared/ios-install-prompt/ios-install-prompt.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '../core/auth/auth.models';
import { selectUser } from '../core/auth/auth.selectors';
import { actionDashboardShowGreetings } from '../features/dashboard/store/dashboard.actions';
import { ShowPictureDialogComponent } from '../shared/show-picture-dialog/show-picture-dialog.component';
import { SoundService } from '../common/services/sound.service';
import { selectNetworkIsOffline, selectNetworkIsSlow } from '../core/network/network.selectors';
import { actionNetworkAskForOffline, actionNetworkFastDetected } from '../core/network/network.actions';
import { AskForOfflineDialogComponent } from '../shared/ask-for-offline-dialog/ask-for-offline-dialog.component';
import { SocialShareDialogComponent } from '../shared/social-share-dialog/social-share-dialog.component';
import { actionCommonAppIsBackground, actionCommonAppIsForeground } from '../common/store/common.actions';

@Component({
    selector: 'anms-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routeAnimations]
})
export class AppComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();

    isProd = env.production;
    envName = env.envName;
    version = env.versions.app;
    year = new Date().getFullYear();
    languages = ['pl', 'en'];

    navigation = [
        { link: 'home', label: 'Pulpit', icon: 'calendar_today' },
        { link: 'map', label: 'Zbieraj śmieci', icon: 'flag' },
        { link: 'areas', label: 'Obszary', icon: 'map' },
        { link: 'activities', label: 'Moje zbiórki', icon: 'add_task' },
        { link: 'coupons', label: 'Kupony', icon: 'account_balance_wallet' },
        { link: 'partners', label: 'Partnerzy', icon: 'handshake' },
        { link: 'help', label: 'Jak to działa?', icon: 'help' },
        { link: 'about', label: 'O aplikacji', icon: 'info' }
    ];

    navigationSideMenu = [...this.navigation];

    isAuthenticated$: Observable<boolean>;
    user$: Observable<User>;
    stickyHeader$: Observable<boolean>;
    language$: Observable<string>;
    theme$: Observable<string>;
    isHeaderVisible$: Observable<boolean>;
    networkStatus$: Subscription = Subscription.EMPTY;
    isSlowNetwork$: Observable<boolean>;
    isOfflineNetwork$: Observable<boolean>;

    isInstallable$: Observable<boolean>;
    isLoading: boolean;
    askForOfflineDialogRef: MatDialogRef<AskForOfflineDialogComponent>;
    isOffline: boolean = false;
    deviceInfo: DeviceInfo;

    constructor(
        private store: Store,
        private actions$: Actions,
        private storageService: LocalStorageService,
        private installService: PwaInstallService,
        private matDialog: MatDialog,
        private spinner: NgxSpinnerService,
        private soundService: SoundService,
        private notificationService: NotificationService,
        private deviceService: DeviceDetectorService
    ) {
        this.deviceInfo = this.deviceService.getDeviceInfo();

        // mobile height fix
        //https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
        const appHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', `${window.innerHeight}px`);
            doc.style.setProperty('--app-width', `${window.innerWidth}px`);
        };
        window.addEventListener('resize', appHeight);
        appHeight();

        window.addEventListener('visibilitychange', () => {
            const state = document.visibilityState;
            if (state === 'hidden') {
                this.store.dispatch(actionCommonAppIsBackground());
            }
            if (state === 'visible') {
                this.store.dispatch(actionCommonAppIsForeground());
            }
        });
    }

    private isIEorEdgeOrSafari() {
        return ['ie', 'edge', 'safari'].includes(this.deviceInfo.browser);
    }

    ngOnInit(): void {
        this.checkNetworkStatus();

        this.actions$.pipe(ofType(actionCoreInitShowiOSInstallPrompt), takeUntil(this.destroy$)).subscribe((result) => {
            IosInstallPromptComponent.Show(this.matDialog);
        });

        this.isInstallable$ = this.store.pipe(select(selectInitIsInstallable));

        this.storageService.testLocalStorage();
        if (this.isIEorEdgeOrSafari()) {
            this.store.dispatch(
                actionSettingsChangeAnimationsPageDisabled({
                    pageAnimationsDisabled: true
                })
            );
        }

        this.store.pipe(takeUntil(this.destroy$), select(selectInitIsLoading)).subscribe((isLoading) => {
            if (isLoading) this.spinner.show();
            else this.spinner.hide();
            this.isLoading = isLoading;
        });

        this.actions$.pipe(ofType(actionDashboardShowGreetings), takeUntil(this.destroy$)).subscribe((action) => {
            let cards = ['/assets/img/screens/greetcard_1', '/assets/img/screens/greetcard_2', '/assets/img/screens/greetcard_3'];
            const imageNumber = Math.floor(Math.random() * cards.length);
            let img = cards[imageNumber];
            let matDialog = this.matDialog;

            ShowPictureDialogComponent.Show2Images(
                img + '.png',
                img + '_back.png',
                false,
                true,
                function () {
                    if (action.activity.id && !this.isOffline) {
                        SocialShareDialogComponent.ShowForActivity(action.activity, imageNumber + 1, matDialog);
                    } else {
                        this.notificationService.info('Opcja niedostępna, jesteś w trybie offline!');
                    }
                },
                this.matDialog
            );
            this.soundService.playFanfare();
        });

        this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
        this.user$ = this.store.pipe(select(selectUser));
        this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
        this.language$ = this.store.pipe(select(selectSettingsLanguage));
        this.theme$ = this.store.pipe(select(selectEffectiveTheme));
        this.isHeaderVisible$ = this.store.pipe(select(selectSettingsHeaderVisible));

        this.isOfflineNetwork$ = this.store.pipe(select(selectNetworkIsOffline));
        this.isSlowNetwork$ = this.store.pipe(select(selectNetworkIsSlow));
    }

    onLoginClick() {}

    onLogoutClick() {
        this.store.dispatch(authLogout());
    }

    onLanguageSelect({ value: language }) {
        this.store.dispatch(actionSettingsChangeLanguage({ language }));
    }

    installApplication(): void {
        this.store.dispatch(actionCoreInitInstall());
    }

    checkNetworkStatus() {
        this.actions$.pipe(ofType(actionNetworkAskForOffline), takeUntil(this.destroy$)).subscribe((result) => {
            if (!this.askForOfflineDialogRef || this.askForOfflineDialogRef.getState() !== MatDialogState.OPEN) {
                this.askForOfflineDialogRef = AskForOfflineDialogComponent.Show('', this.matDialog);
            }
        });

        this.actions$.pipe(ofType(actionNetworkFastDetected), takeUntil(this.destroy$)).subscribe((result) => {
            if (this.askForOfflineDialogRef && this.askForOfflineDialogRef.getState() === MatDialogState.OPEN) {
                this.askForOfflineDialogRef.close();
            }
        });

        this.store.pipe(takeUntil(this.destroy$), select(selectNetworkIsOffline)).subscribe((goOffline) => {
            if (!this.isOffline && goOffline) {
                if (this.askForOfflineDialogRef && this.askForOfflineDialogRef.getState() === MatDialogState.OPEN) {
                    this.askForOfflineDialogRef.close();
                }
                this.notificationService.warn('Kłopoty z połączeniem, włączono tryb offline!', 2000);
            }
            if (this.isOffline && !goOffline) {
                this.notificationService.success('Przywrócono połączenie', 2000);
            }
            this.isOffline = goOffline;
        });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
