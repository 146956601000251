import { GpsLocation } from './gps-location.model';

export class ActivityItem {
    public id?: number;

    public activity_id: number;
    public dropzone_id: number;

    public bag_count: number = 0;
    public garbage_count: number = 0;

    public weight: number = 1;
    public activity_item_type: ActivityItemType;
    public image;
    public lat: number;
    public lng: number;

    public dirty: boolean = true;

    constructor() {}

    public static Position(location: GpsLocation, activity_id: number): ActivityItem {
        let item = new ActivityItem();
        item.activity_item_type = ActivityItemType.POSITION;
        item.lat = location.lat;
        item.lng = location.lng;
        item.activity_id = activity_id;
        return item;
    }

    public static AddGarbage(image: string, location: GpsLocation, activity_id: number): ActivityItem {
        let item = new ActivityItem();
        item.activity_item_type = ActivityItemType.ADDGARBAGE;
        item.lat = location.lat;
        item.image = image;
        item.lng = location.lng;
        item.activity_id = activity_id;
        return item;
    }

    public static RemoveGarbage(
        image: string,
        location: GpsLocation,
        garbageCount: number,
        bagsCount: number,
        dropzone_id: number,
        activity_id: number
    ): ActivityItem {
        let item = new ActivityItem();
        item.activity_item_type = ActivityItemType.REMOVEGARBAGE;
        item.lat = location.lat;
        item.lng = location.lng;
        item.bag_count = bagsCount;
        item.dropzone_id = dropzone_id;
        item.image = image;
        item.garbage_count = garbageCount;
        item.activity_id = activity_id;
        return item;
    }

    public static RemoveGarbageHome(image: string, location: GpsLocation, garbageCount: number, bagsCount: number, activity_id: number): ActivityItem {
        let item = new ActivityItem();
        item.activity_item_type = ActivityItemType.REMOVEGARBAGEHOME;
        item.lat = location.lat;
        item.lng = location.lng;
        item.bag_count = bagsCount;
        item.dropzone_id = null;
        item.image = image;
        item.garbage_count = garbageCount;
        item.activity_id = activity_id;
        return item;
    }
}

export enum ActivityItemType {
    POSITION = 1,
    ADDGARBAGE = 2,
    REMOVEGARBAGE = 3,
    REMOVEGARBAGEHOME = 4
}
