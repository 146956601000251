import { CompileIdentifierMetadata } from '@angular/compiler';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { NotificationService } from '../../core/core.module';
import { actionCommonGpsError, actionCommonInitPlayerLocation, actionCommonSetPlayerLocation } from '../store/common.actions';
import { GpsLocation } from './../models/gps-location.model';

@Injectable({
    providedIn: 'root'
})
export class PlayerLocationService implements OnDestroy {
    private geoLocation: any;
    private watchId: number;
    private currentLocation: GpsLocation;
    private locationListeners: IPlayerLocationListener[] = [];
    public initialized: boolean = false;

    private options: any = {
        enableHighAccuracy: true,
        timeout: 1000,
        maximumAge: 0
    };

    // in meters
    public static AccuracyTreshold = 40;
    public static AccuracyWarningCount = 3;

    private permissionGranted: boolean = false;

    constructor(private not: NotificationService, private store: Store) {
        this.checkPermissions();
    }

    public init(): void {
        if ('geolocation' in navigator) {
            this.geoLocation = navigator.geolocation;
            if (this.permissionGranted) {
                this.initialized = true;
            }
        } else {
            this.errorCallback(null);
        }
    }

    private setCurrentLocation(location: GpsLocation): void {
        this.currentLocation = location;
        for (let listener of this.locationListeners) {
            if (listener) listener.onPlayerLocationChanged(location);
        }

        this.store.dispatch(actionCommonSetPlayerLocation({ location: location }));
    }

    public getLocation(): GpsLocation {
        return this.currentLocation;
    }

    public addLocationListener(listener: IPlayerLocationListener): void {
        if (this.locationListeners.indexOf(listener) == -1) {
            this.locationListeners.push(listener);
        }
    }
    public removeLocationListener(listener: IPlayerLocationListener): void {
        if (this.locationListeners.indexOf(listener) != -1) {
            this.locationListeners.splice(this.locationListeners.indexOf(listener), 1);
        }
    }

    getCurrentLocation(): void {
        this.geoLocation.getCurrentPosition(
            (position) => {
                this.setCurrentLocation(
                    new GpsLocation(position.coords.latitude, position.coords.longitude, position.coords.altitude, position.coords.accuracy)
                );
            },
            (error) => this.errorCallback(error),
            {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 1000
            }
        );
    }

    public startWatch(): void {
        if (!this.initialized) return;

        if (this.watchId) {
            this.geoLocation.clearWatch(this.watchId);
        }
        this.watchId = this.geoLocation.watchPosition(
            (position) => {
                this.setCurrentLocation(
                    new GpsLocation(position.coords.latitude, position.coords.longitude, position.coords.altitude, position.coords.accuracy)
                );
                if (position.coords.accuracy > PlayerLocationService.AccuracyTreshold) {
                }
            },
            (error) => this.errorCallback(error),
            this.options
        );
    }

    private errorCallback(error) {
        if (error.code != 2) {
            this.not.error('Błąd odczytu lokalizacji, sprawdź ustawienia i udziel uprawnień!');
            this.store.dispatch(actionCommonGpsError(error));
        }
    }

    public stopWatch(): void {
        this.geoLocation.clearWatch(this.watchId);
    }

    ngOnDestroy(): void {
        this.stopWatch();
    }

    public checkPermissions() {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'granted') {
                this.permissionGranted = true;
                this.store.dispatch(actionCommonInitPlayerLocation());
            }
            result.addEventListener('change', (value: any) => {
                let state = value.target.state;
                if (state === 'granted') {
                    this.permissionGranted = true;
                    this.store.dispatch(actionCommonInitPlayerLocation());
                } else {
                    this.permissionGranted = false;
                }
            });
        });
    }
}

export interface IPlayerLocationListener {
    onPlayerLocationChanged(location: GpsLocation): void;
}
