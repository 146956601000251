import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../core/core.module';

@Injectable({
    providedIn: 'root'
})
export class LocalPreferencesService {
    public static ScreenManualShown = 'SCREEN_MANUAL_SHOWN';
    public static ScreenGreetingsShownPrefix = 'SCREEN_GREETING_SHOWN_';

    constructor(private localStorage: LocalStorageService) {}
}
