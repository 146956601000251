import { OnInit, OnDestroy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectInitIsLoading } from './init/init.selectors';

@Component({
    template: ''
})
export class BaseComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    store: Store;
    public isLoading$: Observable<boolean>;

    constructor(store: Store) {
        this.store = store;
        this.isLoading$ = this.store.pipe(takeUntil(this.destroy$), select(selectInitIsLoading));
        this.isLoading$.subscribe((value) => {
            if (!value) this.onStopLoading();
        });
    }

    protected onStopLoading(): void {}

    ngOnInit(): void {}
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
