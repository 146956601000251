import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'core',
        loadChildren: () => import('./common/common.module').then((m) => m.CommonModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login-page/login-page.module').then((m) => m.LoginPageModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./pages/register-page/register-page.module').then((m) => m.RegisterPageModule)
    },
    {
        path: 'profile',
        loadChildren: () => import('./pages/profile-page/profile-page.module').then((m) => m.ProfilePageModule)
    },
    {
        path: 'forgot-password/:token',
        loadChildren: () => import('./pages/forgot-password-page/forgot-password-page.module').then((m) => m.ForgotPasswordModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home-page/home-page.module').then((m) => m.HomePageModule)
    },
    {
        path: 'areas',
        loadChildren: () => import('./pages/areas-page/areas-page.module').then((m) => m.AreasPageModule)
    },
    {
        path: 'activities',
        loadChildren: () => import('./pages/activities-page/activities-page.module').then((m) => m.ActivitiesPageModule)
    },
    {
        path: 'map',
        loadChildren: () => import('./pages/map-page/map-page.module').then((m) => m.MapPageModule)
    },
    {
        path: 'help',
        loadChildren: () => import('./pages/help-page/help-page.module').then((m) => m.HelpPageModule)
    },
    {
        path: 'about',
        loadChildren: () => import('./pages/about-page/about-page.module').then((m) => m.AboutPageModule)
    },
    {
        path: 'partners',
        loadChildren: () => import('./pages/partners-page/partners-page.module').then((m) => m.PartnersPageModule)
    },
    {
        path: 'coupons',
        loadChildren: () => import('./pages/coupons-page/coupons-page.module').then((m) => m.CouponsPageModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./features/settings/settings.module').then((m) => m.SettingsModule)
    },
    { path: 'profile-page', loadChildren: () => import('./pages/profile-page/profile-page.module').then((m) => m.ProfilePageModule) },

    {
        path: '**',
        redirectTo: 'home'
    }
];

@NgModule({
    // useHash supports github.io demo page, remove in your app
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: 'enabled',
            preloadingStrategy: PreloadAllModules,
            onSameUrlNavigation: 'ignore'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
