import { Component, OnInit, ChangeDetectionStrategy, HostListener, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { BaseComponent } from '../../core/base.component';
import { actionNetworkStartOffline } from '../../core/network/network.actions';

@Component({
    selector: 'info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoDialogComponent extends BaseComponent implements OnInit {
    public static OK: number = 0;
    public static YES: number = 2;
    public static NO: number = 1;

    InfoDialogComponent = InfoDialogComponent;

    title: string;
    description: string;
    buttons: number[];
    onResult: any;

    @Output() close = new EventEmitter<number>();

    constructor(store: Store, public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super(store);

        this.title = data.title;
        this.description = data.description;
        this.buttons = data.buttons;
        this.onResult = data.onResult;
    }

    ngOnInit(): void {}

    clickClose(result: number) {
        if (this.onResult) {
            this.onResult(result);
        }
        this.dialogRef.close();
    }

    public static Show(title: string, description: string, buttons: number[], onResult: any, dialog: MatDialog): MatDialogRef<InfoDialogComponent> {
        let data: any = { title: title, description: description, buttons: buttons, onResult: onResult };
        const dialogRef = dialog.open(InfoDialogComponent, {
            maxWidth: '360px',
            maxHeight: '200px',
            height: '200px',
            width: '360px',
            data: data,
            panelClass: 'custom-dialog-container'
        });
        return dialogRef;
    }
}
