const packageJson = require('../../../../package.json');

export const environment = {
    appName: 'Zmieć Śmieć',
    envName: 'PROD',
    production: true,
    test: false,
    API_ANONYMOUS_LOGIN_SECRET: 'fkjaso83jsdJN@#',
    api_url: 'https://admin.zmiecsmiec.pl/',
    i18nPrefix: '',
    versions: {
        app: packageJson.version,
        angular: packageJson.dependencies['@angular/core'],
        ngrx: packageJson.dependencies['@ngrx/store'],
        material: packageJson.dependencies['@angular/material'],
        bootstrap: packageJson.dependencies.bootstrap,
        rxjs: packageJson.dependencies.rxjs,
        ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
        fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free-webfonts'],
        angularCli: packageJson.devDependencies['@angular/cli'],
        typescript: packageJson.devDependencies['typescript'],
        cypress: packageJson.devDependencies['cypress']
    }
};
