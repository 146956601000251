<h2 mat-dialog-title>Zrób zdjęcie</h2>
<mat-dialog-content style="overflow: hidden;" fxLayoutAlign="center center">
  
    <div class="m-2 camera-wrapper" #webcamWrapper fxLayoutAlign="center top" (window:resize)="onResize($event)">

        <webcam class="image-responsive"
            [height]="webcamHeight" [width]="webcamWidth" [trigger]="triggerWebcamObservable" 
            (imageCapture)="handleWebcamImage($event)"
            [allowCameraSwitch]="true" [switchCamera]="nextWebcamObservable"
            [imageQuality]="0.92"
            (initError)="handleWebcamInitError($event)"
    ></webcam>

    </div>
    <br/>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>ANULUJ</button>
    <button mat-flat-button class="ml-2" (click)="triggerWebcamSnapshot()" color="primary">ZRÓB ZDJĘCIE</button>
</mat-dialog-actions>