import { Component, OnInit, ChangeDetectionStrategy, HostListener, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'show-picture-dialog',
    templateUrl: './show-picture-dialog.component.html',
    styleUrls: ['./show-picture-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowPictureDialogComponent implements OnInit {
    public image_url: string;
    public image_back_url: string;
    public two_images: boolean = false;
    public doNotShowAgain: boolean = false;
    public withShare: boolean = false;
    public shareCallback: any;
    public showDoNotShowAgain: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ShowPictureDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { image_url: string; image_back_url: string; showDoNotShowAgain: boolean; withShare: boolean; shareCallback: any }
    ) {
        this.image_url = data.image_url;
        this.image_back_url = data.image_back_url;
        this.withShare = data.withShare;
        this.shareCallback = data.shareCallback;

        if (this.image_back_url) this.two_images = true;

        this.showDoNotShowAgain = data.showDoNotShowAgain;

        dialogRef.beforeClosed().subscribe(() => dialogRef.close(this.doNotShowAgain));
    }

    ngOnInit(): void {
        //this.onResize();
    }

    public static Show2Images(
        image_url: string,
        image_back_url: string,
        showDoNotShowAgain: boolean,
        withShare: boolean,
        shareCallback: any,
        dialog: MatDialog
    ): MatDialogRef<ShowPictureDialogComponent> {
        let data: any = {
            image_url: image_url,
            image_back_url: image_back_url,
            showDoNotShowAgain: showDoNotShowAgain,
            withShare: withShare,
            shareCallback: shareCallback
        };
        return ShowPictureDialogComponent.ShowDialog(data, dialog);
    }

    public static Show(image_url: string, showDoNotShowAgain: boolean, dialog: MatDialog): MatDialogRef<ShowPictureDialogComponent> {
        let data: any = { image_url: image_url, showDoNotShowAgain: showDoNotShowAgain };
        return ShowPictureDialogComponent.ShowDialog(data, dialog);
    }

    private static ShowDialog(data: any, dialog: MatDialog): MatDialogRef<ShowPictureDialogComponent> {
        const dialogRef = dialog.open(ShowPictureDialogComponent, {
            maxWidth: '720px',
            maxHeight: '75vh',
            height: '75%',
            width: '80%',
            data: data,
            panelClass: 'show-picture-dialog-container'
        });
        return dialogRef;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?: Event) {
        const win = !!event ? (event.target as Window) : window;
    }
}
