import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { User } from './auth.models';

export const authInit = createAction('[Auth] Init');
export const authInitCompleted = createAction('[Auth] Init Completed', props<{ isAuthenticated: boolean }>());

export const authRefreshTokenSuccess = createAction('[Auth] Refresh Token Success');
export const authRefreshTokenFailure = createAction('[Auth] Refresh Token Failure');

export const authLogin = createAction('[Auth] Login', props<{ email: string; password: string }>());

export const authLoginSuccess = createAction('[Auth] Login Success', props<{ access_token: string; refresh_token: string }>());

export const authAnonymousLogin = createAction('[Auth] Anonymous Login');

export const authLogout = createAction('[Auth] Logout');

export const authLoginFailure = createAction('[Auth] Login Failure', props<{ error: HttpErrorResponse }>());

export const authRegister = createAction('[Auth] Register', props<{ email: string; name: string; password: string; details: any[] }>());
export const authRegisterSuccess = createAction('[Auth] Register Success', props<{ access_token: string; refresh_token: string }>());
export const authRegisterFailure = createAction('[Auth] Register Failure', props<{ error: HttpErrorResponse }>());

export const authGetUser = createAction('[Auth] Get User');
export const authGetUserSuccess = createAction('[Auth] Get User Success', props<{ user: User }>());
export const authGetUserFailure = createAction('[Auth] Get User Failure', props<{ error: string }>());

export const authUserUpdate = createAction('[Auth] Update User', props<{ user: User; avatar: string }>());
export const authUserUpdateSuccess = createAction('[Auth] Update User Success', props<{ user: User }>());
export const authUserUpdateFailure = createAction('[Auth] Update User Failure', props<{ error: string }>());

export const authUserDelete = createAction('[Auth] User Delete');
export const authUserDeleteSuccess = createAction('[Auth] User Delete Success');
export const authUserDeleteFailure = createAction('[Auth] User Delete Failure', props<{ error: string }>());

export const authPasswordResetRequest = createAction('[Auth] Forgot Password Request', props<{ email: string }>());
export const authPasswordResetRequestSuccess = createAction('[Auth] Forgot Password Request Success');
export const authPasswordResetRequestFailure = createAction('[Auth] Forgot Password Request Failure', props<{ error: string }>());

export const authPasswordResetApply = createAction('[Auth] Password Reset Apply', props<{ email: string; password: string; token: string }>());
export const authPasswordResetApplySuccess = createAction('[Auth] Password Reset Apply Success');
export const authPasswordResetApplyFailure = createAction('[Auth] Password Reset Apply Failure', props<{ error: string }>());
