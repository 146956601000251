import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { of, pipe } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'anms-splash',
    templateUrl: './splash.component.html',
    styleUrls: ['./splash.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashComponent implements OnInit {
    constructor(private store: Store) {}

    ngOnInit(): void {
        //this.store.dispatch(actionCoreInitInstanceId());
    }
}
