import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { actionCoreInitSetIsInstallable, actionCoreInitSetIsInstalled, actionCoreInitShowiOSInstallPrompt } from '../init/init.actions';
import { DeviceDetectorService } from 'ngx-device-detector';

import { OS } from 'ngx-device-detector';
import { filter } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PwaInstallService {
    deferredBeforeInstallPrompt;

    isInstalled: boolean = false;

    constructor(private appRef: ApplicationRef, private store: Store, private updates: SwUpdate, private deviceService: DeviceDetectorService) {
        this.addBeforeInstallListener();
        this.addDisplayModeListener();
        this.addIsInstalledListener();
        this.addUpdateListener();

        if (this.isiOSDeviceReady()) {
            this.tryToShowiOSInstallPrompt();
        }
    }

    addUpdateListener() {
        if (this.updates.isEnabled) {
            console.log('PWA Updates Enabled');

            this.updates.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')).subscribe((evt) => {
                console.log('Install / reload');
                document.location.reload();
            });
        }
    }

    public addBeforeInstallListener(): void {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredBeforeInstallPrompt = e;
            //showInstallPromotion();
            console.log('PWA Before Install');

            this.store.dispatch(actionCoreInitSetIsInstallable({ value: !this.isInstalled && !this.isiOSDeviceReady() }));
        });
    }

    public async tryToInstall() {
        if (!this.deferredBeforeInstallPrompt) return;

        this.deferredBeforeInstallPrompt.prompt();
        const { outcome } = await this.deferredBeforeInstallPrompt.userChoice;
        this.deferredBeforeInstallPrompt = null;
    }

    public addIsInstalledListener(): void {
        window.addEventListener('appinstalled', () => {
            //hideInstallPromotion();
            this.deferredBeforeInstallPrompt = null;
            this.isInstalled = true;
            this.store.dispatch(actionCoreInitSetIsInstalled({ value: true }));
        });
    }

    public getPWADisplayMode() {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (document.referrer.startsWith('android-app://')) {
            return 'pwa';
        } else if (('standalone' in window.navigator && window.navigator['standalone']) || isStandalone) {
            return 'standalone';
        }
        return 'browser';
    }

    public addDisplayModeListener(): void {
        window.matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
            let displayMode = 'browser';
            if (evt.matches) {
                displayMode = 'standalone';
            }
            console.log('DISPLAY_MODE_CHANGED', displayMode);
        });
    }

    public isiOSDeviceReady(): boolean {
        let deviceInfo = this.deviceService.getDeviceInfo();
        return deviceInfo.os === OS.IOS && !(this.getPWADisplayMode() === 'standalone');
    }

    private tryToShowiOSInstallPrompt(): void {
        const storageKey = 'iosInstallPrompt';
        const promptOnVisit = 1;
        const timesToShow = 5;
        const debug = false;

        let iOSPromptData = JSON.parse(localStorage.getItem(storageKey));
        if (iOSPromptData === null) {
            iOSPromptData = { isiOS: true, visits: 0, doNotShowAgain: false };
            localStorage.setItem(storageKey, JSON.stringify(iOSPromptData));
        }

        const aboveMinVisits = iOSPromptData.visits + 1 >= promptOnVisit;
        const belowMaxVisits = iOSPromptData.visits + 1 < promptOnVisit + timesToShow;

        if (belowMaxVisits || debug) {
            localStorage.setItem(storageKey, JSON.stringify({ ...iOSPromptData, visits: iOSPromptData.visits + 1 }));
            if (aboveMinVisits || debug) {
                setTimeout(() => this.store.dispatch(actionCoreInitShowiOSInstallPrompt()), 5000);
            }
        }
    }
}
