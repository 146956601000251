import { createSelector } from '@ngrx/store';
import { ActivityStatus } from '../../../common/models/activity.model';
import { ActivitiesState, selectActivitiesState } from '../activities.state';

export const selectActivitiesByStatus = (status: ActivityStatus) =>
    createSelector(selectActivitiesState, (state: ActivitiesState) => state.items?.filter((item) => item.status == status));

export const selectActivities = createSelector(selectActivitiesState, (state: ActivitiesState) => state?.items);
export const selectActivitiesHasLocalData = createSelector(selectActivitiesState, (state: ActivitiesState) => state?.hasLocalData);

export const selectActivitiesCurrent = createSelector(
    selectActivitiesState,
    (state: ActivitiesState) => state?.items?.filter((item) => item.status == ActivityStatus.CURRENT)[0]
);
export const selectActivitiesLastPositionSaveTime = createSelector(selectActivitiesState, (state: ActivitiesState) => state?.lastPositionSaveTime);

export const selectActivitiesEmpty = createSelector(selectActivitiesState, (state: ActivitiesState) => state.items?.length == 0);

export const selectActivitiesIsUserInsideCurrentArea = createSelector(selectActivitiesState, (state: ActivitiesState) => state.isUserInsideCurrentArea);

export const selectActivitiesDirty = createSelector(selectActivitiesState, (state: ActivitiesState) => state.hasLocalData);
