import { Action, createReducer, on } from '@ngrx/store';
import { UserStatsView } from '../../../common/models/user-stats-view.model';
import { DashboardState } from '../dashboard.state';
import { actionDashboardGetNewsSuccess, actionDashboardGetUserStatsSuccess } from './dashboard.actions';

export const initialState: DashboardState = {
    news: [],
    userStats: new UserStatsView()
};

const reducer = createReducer(
    initialState,
    on(actionDashboardGetNewsSuccess, (state, { news }) => {
        return {
            ...state,
            news: news
        };
    }),
    on(actionDashboardGetUserStatsSuccess, (state, { stats }) => {
        return {
            ...state,
            userStats: stats
        };
    })
);

export function dashboardReducer(state: DashboardState | undefined, action: Action) {
    return reducer(state, action);
}
