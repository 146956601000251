import { Component, OnInit, ChangeDetectionStrategy, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'anms-take-picture-dialog',
    templateUrl: './take-picture-dialog.component.html',
    styleUrls: ['./take-picture-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakePictureDialogComponent implements OnInit, AfterViewInit {
    // webcam items
    private triggerWebcam: Subject<void> = new Subject<void>();
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
    private multipleWebcamsAvailable: boolean = false;
    private webcamImage: WebcamImage;
    webcamWidth: number = 300;
    webcamHeight: number = 200;

    @ViewChild('webcamWrapper')
    webcamWrapper: ElementRef;

    constructor(public dialogRef: MatDialogRef<TakePictureDialogComponent>) {}
    ngAfterViewInit(): void {
        this.onResize();
    }

    ngOnInit(): void {
        WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
            this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        });
    }

    public static Show(dialog: MatDialog): MatDialogRef<TakePictureDialogComponent> {
        const dialogRef = dialog.open(TakePictureDialogComponent, {
            maxWidth: '700px',
            maxHeight: '90vh',
            height: '75%',
            width: '85%',
            panelClass: 'custom-dialog-container'
        });
        return dialogRef;
    }

    // WEBCAM STUFF
    public get triggerWebcamObservable(): Observable<void> {
        return this.triggerWebcam.asObservable();
    }
    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }
    public triggerWebcamSnapshot(): void {
        this.triggerWebcam.next();
    }
    public handleWebcamImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;

        this.dialogRef.close(this.webcamImage);
    }
    public handleWebcamInitError(error: WebcamInitError): void {
        //this.errors.push(error);
    }
    public showNextWebcam(directionOrDeviceId: boolean | string): void {
        this.nextWebcam.next(directionOrDeviceId);
    }

    onResize(event?: Event) {
        const win = !!event ? (event.target as Window) : window;
        if (this.webcamWrapper) {
            this.webcamWidth = (this.webcamWrapper.nativeElement as HTMLElement).offsetWidth;
            this.webcamHeight = win.innerHeight;
        }
    }
}
