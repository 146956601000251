import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxSpinnerModule } from 'ngx-spinner';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
    faPlus,
    faEdit,
    faTrash,
    faTimes,
    faCaretUp,
    faCaretDown,
    faExclamationTriangle,
    faFilter,
    faTasks,
    faCheck,
    faSquare,
    faLanguage,
    faPaintBrush,
    faLightbulb,
    faWindowMaximize,
    faStream,
    faBook,
    faShare,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { faMediumM, faGithub } from '@fortawesome/free-brands-svg-icons';

import { BigInputComponent } from './big-input/big-input/big-input.component';
import { BigInputActionComponent } from './big-input/big-input-action/big-input-action.component';
import { RtlSupportDirective } from './rtl-support/rtl-support.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { FlexModule } from '@angular/flex-layout';
import { CountUpModule } from 'ngx-countup';
import { WebcamModule } from 'ngx-webcam';
import { TakePictureDialogComponent } from './take-picture-dialog/take-picture-dialog.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { InfoBanerComponent } from './info-baner/info-baner.component';
import { RouterModule } from '@angular/router';
import { IosInstallPromptComponent } from './ios-install-prompt/ios-install-prompt.component';
import { ShowPictureDialogComponent } from './show-picture-dialog/show-picture-dialog.component';
import { SocialShareDialogComponent } from './social-share-dialog/social-share-dialog.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { AskForOfflineDialogComponent } from './ask-for-offline-dialog/ask-for-offline-dialog.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ChoosePictureComponent } from './choose-picture/choose-picture.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { GpsPermissionDialogComponent } from './gps-permission-dialog/gps-permission-dialog.component';
import { SafeHtmlPipe } from './safe-html-pipe/safe-html.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        MatFormFieldModule,
        TranslateModule,
        MatButtonModule,
        MatSelectModule,
        MatTabsModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatCardModule,
        MatCheckboxModule,
        MatRadioModule,
        MatProgressBarModule,
        MatListModule,
        MatMenuModule,
        MatSnackBarModule,
        MatDialogModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatDividerModule,
        SafeHtmlPipe,
        MatIconModule,
        FontAwesomeModule,
        FlexModule,
        CountUpModule,
        WebcamModule,
        MatSelectModule,
        MatButtonToggleModule,
        ShareIconsModule,
        ShareModule,
        ShareButtonsModule,
        ShareIconsModule,
        FontAwesomeModule,
        NgxSpinnerModule
    ],
    declarations: [
        BigInputComponent,
        BigInputActionComponent,
        RtlSupportDirective,
        TakePictureDialogComponent,
        ShowPictureDialogComponent,
        InfoBanerComponent,
        IosInstallPromptComponent,
        SocialShareDialogComponent,
        GpsPermissionDialogComponent,
        AskForOfflineDialogComponent,
        ChoosePictureComponent,
        InfoDialogComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        WebcamModule,
        TranslateModule,
        CountUpModule,
        MatButtonModule,
        MatMenuModule,
        MatTabsModule,
        MatChipsModule,
        MatInputModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatCardModule,
        MatListModule,
        MatRadioModule,
        MatSelectModule,
        MatProgressBarModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatSliderModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatDialogModule,
        MatIconModule,
        FontAwesomeModule,
        FlexModule,
        MatSelectModule,
        BigInputComponent,
        BigInputActionComponent,
        RtlSupportDirective,
        MatButtonToggleModule,
        ShareModule,
        ShareButtonsModule,
        ShareIconsModule,
        FontAwesomeModule,
        InfoBanerComponent,
        IosInstallPromptComponent,
        NgxSpinnerModule,
        SocialShareDialogComponent,
        AskForOfflineDialogComponent,
        ChoosePictureComponent,
        InfoDialogComponent
    ]
})
export class SharedModule {
    constructor(faIconLibrary: FaIconLibrary) {
        faIconLibrary.addIcons(
            faGithub,
            faMediumM,
            faPlus,
            faEdit,
            faTrash,
            faTimes,
            faCaretUp,
            faCaretDown,
            faExclamationTriangle,
            faFilter,
            faTasks,
            faCheck,
            faSquare,
            faLanguage,
            faPaintBrush,
            faLightbulb,
            faWindowMaximize,
            faStream,
            faBook,
            faShare,
            faTimesCircle
        );
    }
}
