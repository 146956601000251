import { createAction, props } from '@ngrx/store';
import { Activity } from '../../../common/models/activity.model';
import { News } from '../../../common/models/news.model';
import { UserStatsView } from '../../../common/models/user-stats-view.model';

export const actionDashboardGetNews = createAction('[DASH] Get News');
export const actionDashboardGetNewsSuccess = createAction('[DASH] Get News Success', props<{ news: News[] }>());
export const actionDashboardGetNewsFailure = createAction('[DASH] Get News Failure', props<{ error: string }>());

export const actionDashboardGetUserStats = createAction('[DASH] Get User Stats');
export const actionDashboardGetUserStatsSuccess = createAction('[DASH] Get User Stats Success', props<{ stats: UserStatsView }>());
export const actionDashboardGetUserStatsFailure = createAction('[DASH] Get User Stats Failure', props<{ error: string }>());

export const actionDashboardShowGreetings = createAction('[DASH] Show Greetings', props<{ name: string; activity: Activity }>());
