import { createAction, props } from '@ngrx/store';

export const actionCoreIsNotInitialized = createAction('[INIT] Not Initialized', props<{ redirect_url: string }>());

export const actionCoreInit = createAction('[INIT] Start');

export const actionCoreInitCompleted = createAction('[INIT] Completed');

export const actionCoreInitGetInstanceId = createAction('[INIT] Get Instance Id');
export const actionCoreInitGetInstanceIdSuccess = createAction('[INIT] Get Instance Id Success', props<{ id: string }>());

export const actionCoreInitInstall = createAction('[INIT] Install');

export const actionCoreInitSetIsInstallable = createAction('[INIT] Set Is Installable', props<{ value: boolean }>());
export const actionCoreInitSetIsInstalled = createAction('[INIT] Set Is Installed', props<{ value: boolean }>());

export const actionCoreInitShowiOSInstallPrompt = createAction('[INIT] iOS Install Prompt');

export const actionCoreIsLoading = createAction('[INIT] Is Loading', props<{ value: boolean }>());
