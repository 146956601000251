import { Injectable } from '@angular/core';
import { Area } from '../../common/models/area.model';
import { MapUtilsService } from '../../common/services/map-utils.service';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import * as L from 'leaflet';
import { tileLayer } from 'leaflet';
import { from, Observable, of } from 'rxjs';
import { MapsOfflineDatabase, MapsOfflineDatabaseTile } from './maps-offline-database';
import { PromiseExtended } from 'dexie';
import { MapsOfflineLayer } from './maps-offline-layer';

@Injectable({
    providedIn: 'root'
})
export class MapsOfflineService {
    private map: L.Map;
    private db: MapsOfflineDatabase;
    private offlineLayer: MapsOfflineLayer;

    private tilesToDownload: MapsOfflineDatabaseTile[] = [];

    constructor(private store: Store, private actions$: Actions, private mapUtilsService: MapUtilsService) {
        this.db = new MapsOfflineDatabase();

        this.offlineLayer = new MapsOfflineLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 16 }, this.db);
    }

    public getOfflineLayer(): MapsOfflineLayer {
        return this.offlineLayer;
    }

    public addAreasToDownload(areas: Area[]): Observable<number> {
        //this.tilesToDownload = [];
        let tiles: MapsOfflineDatabaseTile[] = [];

        let a = performance.now();
        for (let area of areas) {
            tiles = tiles.concat(this.getTilesForArea(area));
            break;
        }
        this.tilesToDownload = this.tilesToDownload.concat(tiles);
        //console.log(performance.now() - a);
        return of(this.tilesToDownload.length);
    }

    public getAreaOfflineSize(area: Area): number {
        const tiles = this.getTilesForArea(area);
        return tiles.length * 10 * 1024;
    }

    private getTilesForArea(area: Area): MapsOfflineDatabaseTile[] {
        let tiles: MapsOfflineDatabaseTile[] = [];

        let mainLayer = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { id: 'main', maxZoom: 18 });
        const polygon = L.polygon(area.coordinates);
        const nw = polygon.getBounds().getNorthWest();
        const se = polygon.getBounds().getSouthEast();

        for (let zoom = 16; zoom > 2; zoom--) {
            const p1 = L.CRS.EPSG3857.latLngToPoint(nw, zoom).floor();
            const p2 = L.CRS.EPSG3857.latLngToPoint(se, zoom).floor();
            const areaBounds = L.bounds(p1, p2);
            tiles = tiles.concat(this.getTileUrls(mainLayer, areaBounds, zoom));
        }
        return tiles;
    }

    public downloadNextTile(): Observable<number> {
        let tileToDownload = this.tilesToDownload.pop();
        const left: number = this.tilesToDownload.length;

        return from(
            this.db.tileExists(tileToDownload.id).then((item) => {
                if (item == null)
                    return fetch(tileToDownload.url).then((response) => {
                        if (!response.ok) {
                            throw new Error(`Request failed with status ${response.statusText}`);
                        }
                        return this.db.storeTile(tileToDownload, response.blob()).then(() => {
                            return left;
                        });
                    });
                else {
                    return left;
                }
            })
        );
    }

    public static GetTileUrl(urlTemplate, data) {
        return L.Util.template(urlTemplate, {
            ...data,
            r: L.Browser.retina ? '@2x' : ''
        });
    }

    public static GetTileId(data) {
        return data.z + '|' + data.x + '|' + data.y;
    }

    private getTileUrls(layer, bounds, zoom) {
        const tiles: MapsOfflineDatabaseTile[] = [];
        const tileBounds = L.bounds(bounds.min.divideBy(layer.getTileSize().x).floor(), bounds.max.divideBy(layer.getTileSize().x).floor());
        for (let j = tileBounds.min.y; j <= tileBounds.max.y; j += 1) {
            for (let i = tileBounds.min.x; i <= tileBounds.max.x; i += 1) {
                const tilePoint = new L.Point(i, j);
                const data = {
                    ...layer.options,
                    x: i,
                    y: j,
                    z: zoom
                };
                tiles.push({
                    id: MapsOfflineService.GetTileId({
                        ...data,
                        s: layer.options.subdomains['0']
                    }),
                    url: MapsOfflineService.GetTileUrl(layer._url, {
                        ...data,
                        s: layer._getSubdomain(tilePoint)
                    }),
                    z: zoom,
                    x: i,
                    y: j,
                    urlTemplate: layer._url,
                    when: Date.now(),
                    data: null
                });
            }
        }

        return tiles;
    }
}
