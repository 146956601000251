import { createSelector } from '@ngrx/store';
import { selectCoreInitState } from '../core.state';
import { InitState } from './init.state';

export const selectCoreIsInitialized = createSelector(selectCoreInitState, (state: InitState) => state.isInitialized);
export const selectInitRedirectAfterInitUrl = createSelector(selectCoreInitState, (state: InitState) => state.redirectAfterInitUrl);
export const selectInitInstanceId = createSelector(selectCoreInitState, (state: InitState) => state.instanceId);

export const selectInitIsInstalled = createSelector(selectCoreInitState, (state: InitState) => state.isInstalled);
export const selectInitIsInstallable = createSelector(selectCoreInitState, (state: InitState) => state.isInstallable);

export const selectInitIsLoading = createSelector(selectCoreInitState, (state: InitState) => state.isLoading);
