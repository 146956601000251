import { Action, createReducer, on } from '@ngrx/store';
import { Area } from '../../../common/models/area.model';
import { actionCommonSetPlayerLocation } from '../../../common/store/common.actions';
import { authGetUserSuccess } from '../../../core/auth/auth.actions';
import { AreasState } from '../areas.state';
import {
    actionAreasDeselectArea,
    actionAreasGet,
    actionAreasGetArea,
    actionAreasGetAreaSuccess,
    actionAreasGetSuccess,
    actionAreasMapsDownloadProgress,
    actionAreasMapsDownloadStart,
    actionAreasMapsDownloadStop,
    actionAreasSetByDistance,
    actionAreasSetUserInsideAreas,
    actionAreasToggleFavSuccess
} from './areas.actions';

export const initialState: AreasState = {
    areas: [],
    areasByDistance: [],
    selectedArea: null,
    userInside: [],
    mapsDownloadInProgress: false,
    mapsDownloadProgress: 0,
    nearest: null,
    favorites: []
};

const reducer = createReducer(
    initialState,
    on(actionAreasSetByDistance, (state, { areas }) => {
        let nearest = null;
        if (areas.length > 0) nearest = areas[0];
        return {
            ...state,
            nearest: nearest,
            areasByDistance: areas
        };
    }),
    on(actionAreasGetSuccess, (state, { areas }) => {
        if (state.areasByDistance.length == 0) {
            return {
                ...state,
                areas: areas,
                areasByDistance: areas
            };
        } else {
            return {
                ...state,
                areas: areas
            };
        }
    }),
    on(actionAreasDeselectArea, (state) => {
        return {
            ...state,
            selectedArea: null
        };
    }),
    on(actionAreasMapsDownloadStart, (state) => {
        return {
            ...state,
            mapsDownloadInProgress: true,
            mapsDownloadProgress: 0
        };
    }),
    on(actionAreasMapsDownloadStop, (state) => {
        return {
            ...state,
            mapsDownloadInProgress: false
        };
    }),
    on(actionAreasMapsDownloadProgress, (state, { total, left }) => {
        return {
            ...state,
            mapsDownloadProgress: Math.round(((total - left) / total) * 100)
        };
    }),
    on(actionAreasGetAreaSuccess, (state, { area }) => {
        return {
            ...state,
            selectedArea: area
        };
    }),
    on(actionAreasSetUserInsideAreas, (state, { areas }) => {
        return {
            ...state,
            userInside: areas
        };
    }),
    on(authGetUserSuccess, (state, { user }) => {
        return {
            ...state,
            favorites: user.favs['areas']
        };
    }),
    on(actionAreasToggleFavSuccess, (state, { favs }) => {
        return {
            ...state,
            favorites: favs
        };
    })
);

export function areasReducer(state: AreasState | undefined, action: Action) {
    return reducer(state, action);
}
