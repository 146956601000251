import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions, ROOT_EFFECTS_INIT, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, delay, exhaustMap, filter, map, mapTo, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppCoreService } from '../../common/services/app-core.service';
import { MainApiService } from '../../common/services/main-api.service';
import { selectNetworkState } from '../core.state';
import {
    actionNetworkAskForOffline,
    actionNetworkFastDetected,
    actionNetworkPingFailure,
    actionNetworkPingSuccess,
    actionNetworkSlowDetected,
    actionNetworkStartMonitoring,
    actionNetworkStartOffline,
    actionNetworkStopOffline
} from './network.actions';
import { networkFailedPingsTreshold, networkPingDelay, networkSlowConnectionTreshold, networkSuccessPingsTreshold } from './network.reducer';
import { selectNetworkMonitoringActive } from './network.selectors';

@Injectable()
export class NetworkEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private coreService: AppCoreService,
        private apiService: MainApiService
    ) {}

    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            delay(2000),
            map((action) => actionNetworkStartMonitoring())
        )
    );

    ping$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionNetworkStartMonitoring, actionNetworkPingFailure, actionNetworkPingSuccess),
            withLatestFrom(this.store.pipe(select(selectNetworkMonitoringActive))),
            filter(([action, isMonitoringActive]) => isMonitoringActive),
            delay(networkPingDelay),
            switchMap((action) => {
                const begin = performance.now();
                return this.apiService.pingApi().pipe(
                    map((timestamp) => {
                        return actionNetworkPingSuccess({ time: performance.now() - begin });
                    }),
                    catchError((error) => of(actionNetworkPingFailure()))
                );
            })
        )
    );

    checkBackToOnline$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionNetworkPingSuccess),
            withLatestFrom(this.store.pipe(select(selectNetworkState))),
            filter(([action, networkState]) => networkState.isOffline),
            filter(([action, networkState]) => networkState.pingsSuccess >= networkSuccessPingsTreshold),
            filter(([action, networkState]) => networkState.avgPingTime < networkSlowConnectionTreshold),
            map(([action, networkState]) => {
                return actionNetworkStopOffline();
            })
        )
    );

    checkPingFailedInARow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionNetworkPingFailure),
            withLatestFrom(this.store.pipe(select(selectNetworkState))),
            filter(([action, networkState]) => !networkState.isOffline),
            filter(([action, networkState]) => networkState.pingsFailed >= networkFailedPingsTreshold),
            map(([action, networkState]) => {
                return actionNetworkStartOffline();
            })
        )
    );

    checkPingTimeToOnline$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionNetworkPingSuccess),
            withLatestFrom(this.store.pipe(select(selectNetworkState))),
            filter(([action, networkState]) => !networkState.isOffline),
            filter(([action, networkState]) => networkState.slowNetwork),
            filter(([action, networkState]) => networkState.avgPingTime < networkSlowConnectionTreshold),
            filter(([action, networkState]) => networkState.pingsSuccess >= networkSuccessPingsTreshold),
            map(([action, networkState]) => {
                return actionNetworkFastDetected();
            })
        )
    );
    checkPingTimeToOffline$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionNetworkPingSuccess),
            withLatestFrom(this.store.pipe(select(selectNetworkState))),
            filter(([action, networkState]) => !networkState.isOffline),
            filter(([action, networkState]) => !networkState.slowNetwork),
            filter(([action, networkState]) => networkState.avgPingTime >= networkSlowConnectionTreshold),
            filter(([action, networkState]) => networkState.pingsSuccess >= networkSuccessPingsTreshold),
            map(([action, networkState]) => {
                return actionNetworkSlowDetected();
            })
        )
    );

    askForOffline$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionNetworkSlowDetected),
            map((action) => actionNetworkAskForOffline())
        )
    );
}
