import { ActionReducerMap, MetaReducer, createFeatureSelector } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { environment } from '../../environments/environment';

import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { debug } from './meta-reducers/debug.reducer';
import { AuthState } from './auth/auth.models';
import { authReducer } from './auth/auth.reducer';
import { RouterStateUrl } from './router/router.state';
import { settingsReducer } from './settings/settings.reducer';
import { SettingsState } from './settings/settings.model';
import { InitState } from './init/init.state';
import { initReducer } from './init/init.reducer';
import { NetworkState } from './network/network.state';
import { networkReducer } from './network/network.reducer';

export const reducers: ActionReducerMap<AppState> = {
    auth: authReducer,
    settings: settingsReducer,
    router: routerReducer,
    init: initReducer,
    network: networkReducer
};

export const metaReducers: MetaReducer<AppState>[] = [];

if (!environment.production) {
    if (!environment.test) {
        metaReducers.unshift(debug);
    }
}

export const selectCoreInitState = createFeatureSelector<AppState, InitState>('init');
export const selectAuthState = createFeatureSelector<AppState, AuthState>('auth');
export const selectSettingsState = createFeatureSelector<AppState, SettingsState>('settings');
export const selectRouterState = createFeatureSelector<AppState, RouterReducerState<RouterStateUrl>>('router');
export const selectNetworkState = createFeatureSelector<AppState, NetworkState>('network');

export interface AppState {
    auth: AuthState;
    settings: SettingsState;
    router: RouterReducerState<RouterStateUrl>;
    init: InitState;
    network: NetworkState;
}
