import { NgModule } from '@angular/core';
import { DataApiService } from './services/data-api-service';
import { PlayerLocationService } from './services/player-location.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { Configuration } from '../app.constants';
import { SplashComponent } from './components/splash/splash.component';
import { MainApiService } from './services/main-api.service';
import { AppCoreService } from './services/app-core.service';
import { CommonRoutingModule } from './common.routing.module';
import { FEATURE_NAME } from './common.state';
import { commonReducer } from './store/common.reducer';
import { CommonEffects } from './store/common.effects';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { AreasEffects } from '../features/areas/store/areas.effects';
import { MapUtilsService } from './services/map-utils.service';
import { ActivitiesEffects } from '../features/activities/store/activities.effects';
import { areasReducer } from '../features/areas/store/areas.reducer';
import { activitiesReducer } from '../features/activities/store/activities.reducer';
import { dashboardReducer } from '../features/dashboard/store/dashoboard.reducer';
import { DashboardEffects } from '../features/dashboard/store/dashboard.effects';
import { dropzonesReducer } from '../features/dropzones/store/dropzones.reducer';
import { DropzonesEffects } from '../features/dropzones/store/dropzones.effects';
import { SoundService } from './services/sound.service';
import { LocalPreferencesService } from './services/local-preferences.service';

@NgModule({
    declarations: [SplashComponent],
    imports: [
        AngularCommonModule,
        CommonRoutingModule,
        StoreModule.forFeature(FEATURE_NAME, commonReducer),
        StoreModule.forFeature('areas', areasReducer),
        StoreModule.forFeature('activities', activitiesReducer),
        StoreModule.forFeature('dropzones', dropzonesReducer),
        StoreModule.forFeature('dashboard', dashboardReducer),

        EffectsModule.forFeature([CommonEffects, DropzonesEffects, DashboardEffects, AreasEffects, ActivitiesEffects])
    ],
    providers: [DataApiService, SoundService, LocalPreferencesService, PlayerLocationService, MainApiService, Configuration, AppCoreService, MapUtilsService]
})
export class CommonModule {}
