import * as L from 'leaflet';
import { MapsOfflineDatabase } from './maps-offline-database';
import { MapsOfflineService } from './maps-offline.service';

export class MapsOfflineLayer extends L.TileLayer {
    private db: MapsOfflineDatabase;
    private url: string;

    constructor(url, options, db) {
        super(url, options);
        this.db = db;
        this.url = url;
    }

    protected setDataUrl(coords: L.Coords) {
        return this.db.getTile(this.getStorageKey(coords)).then((data) => {
            if (data && typeof data === 'object') {
                return URL.createObjectURL(data);
            }
            throw new Error('tile not found in storage');
        });
    }

    private getStorageKey(coords: L.Coords): string {
        const id = MapsOfflineService.GetTileId({
            ...coords,
            ...this.options,
            s: this.options.subdomains['0']
        });
        return id;
    }

    protected override createTile(coords: L.Coords, done: L.DoneCallback): HTMLElement {
        let error;

        const tile: any = super.createTile(coords, () => {});
        const url = tile.src;
        tile.src = '';

        this.setDataUrl(coords)
            .then((dataurl) => {
                tile.src = dataurl;
                done(error, tile);
            })
            .catch(() => {
                tile.src = url;
                L.DomEvent.on(tile, 'load', L.Util.bind(this._tileOnLoad, this, done, tile));
                L.DomEvent.on(tile, 'error', L.Util.bind(this._tileOnError, this, done, tile));
            });

        return tile;
    }
}
