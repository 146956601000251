import { createAction, props } from '@ngrx/store';

export const actionNetworkStartMonitoring = createAction('[NETWORK] Start monitoring');

export const actionNetworkPingFailure = createAction('[NETWORK] Ping failed');
export const actionNetworkPingSuccess = createAction('[NETWORK] Ping success', props<{ time: number }>());
export const actionNetworkAskForOffline = createAction('[NETWORK] Ask for offline');
export const actionNetworkStartOffline = createAction('[NETWORK] Start offline');
export const actionNetworkStopOffline = createAction('[NETWORK] Stop offline');
export const actionNetworkSlowDetected = createAction('[NETWORK] Slow detected');
export const actionNetworkFastDetected = createAction('[NETWORK] Fast detected');
