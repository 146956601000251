import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CommonModule as AngularCommonModule, registerLocaleData } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from './common/common.module';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { PwaInstallService } from './core/pwa/pwa-install.service';
import localePl from '@angular/common/locales/pl';

registerLocaleData(localePl);

@NgModule({
    imports: [
        // angular
        BrowserAnimationsModule,
        BrowserModule,

        // core
        AngularCommonModule,
        CoreModule,
        CommonModule,

        // app
        AppRoutingModule,
        FlexLayoutModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

        //
        SharedModule,

        NgxGoogleAnalyticsModule.forRoot('G-ERCH6H2GD0'),
        NgxGoogleAnalyticsRouterModule
    ],
    declarations: [AppComponent],
    providers: [PwaInstallService, { provide: LOCALE_ID, useValue: 'pl' }],
    bootstrap: [AppComponent]
})
export class AppModule {}
