import { Component, OnInit, ChangeDetectionStrategy, HostListener, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { actionCommonLocationPermissionPrompt } from '../../common/store/common.actions';
import { BaseComponent } from '../../core/base.component';
import { actionNetworkStartOffline } from '../../core/network/network.actions';

@Component({
    selector: 'gps-permission-dialog',
    templateUrl: './gps-permission-dialog.component.html',
    styleUrls: ['./gps-permission-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GpsPermissionDialogComponent extends BaseComponent implements OnInit {
    constructor(store: Store, public dialogRef: MatDialogRef<GpsPermissionDialogComponent>) {
        super(store);
    }

    ngOnInit(): void {}

    allow(): void {
        this.store.dispatch(actionCommonLocationPermissionPrompt());
        this.dialogRef.close();
    }

    public static Show(dialog: MatDialog): MatDialogRef<GpsPermissionDialogComponent> {
        const dialogRef = dialog.open(GpsPermissionDialogComponent, {
            maxWidth: '330px',
            width: '360px',
            panelClass: 'custom-dialog-container'
        });
        return dialogRef;
    }
}
