<form class="center">
<div  role="button" fxFlex="90" fxFlex.xs="60" class="p-2 center choose-picture-containter" (click)="select()">
    <input type="file" style="display: none;" capture #choosePictureInput accept="image/*" (change)="fileChangeEvent($event)" class="fileInputProfile">
    <div class="">
        <img *ngIf="isImageSaved" [src]="picture" class="image-responsive" />
        <img *ngIf="!isImageSaved" [src]="placeholder" class="image-responsive">
    </div>

    <div class="take-button">
    </div>

{{imageError}}
</div>  
</form>
