import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Configuration } from '../../app.constants';
import { Observable } from 'rxjs';

@Injectable()
export class DataApiService {
    protected endPoint = 'http://localhost';

    constructor(protected http: HttpClient, private _configuration: Configuration) {
        this.endPoint = this.getApiUrl();
    }

    getApiUrl(): string {
        return this._configuration.ServerWithApiUrl;
    }

    get<T>(url): Observable<T> {
        return this.http.get<T>(this.endPoint + url);
    }

    post<T>(url, body): Observable<T> {
        return this.http.post<T>(this.endPoint + url, body);
    }

    put<T>(url, body): Observable<T> {
        return this.http.put<T>(this.endPoint + url, body);
    }
}
