<div class="show-picture-container">
    <ng-template *ngIf="!two_images" [ngTemplateOutlet]="buttons"></ng-template> 
    <img *ngIf="!two_images" [src]="image_url" width="100%"  class="top-margin image-responsive" />

    <div *ngIf="two_images" [style.background-image]="'url(' + image_back_url + ')'" style="display:block;width:100%; height:100%;" >
        <img [src]="image_url" class="image-center" />
        <ng-template [ngTemplateOutlet]="buttons"></ng-template> 

    </div>

</div>

<ng-template #buttons>

    <div fxLayout="row" class="floating-options">
        <div fxFlex="50" class="mt-2" fxLayoutAlign="start start">
            <button *ngIf="withShare" mat-button mat-raised-button (click)="shareCallback()" color="link"><mat-icon>share</mat-icon> podziel się</button>
            <mat-checkbox *ngIf="showDoNotShowAgain" [(ngModel)]="doNotShowAgain">Nie pokazuj więcej</mat-checkbox>
        </div>
        <div fxFlex="50" fxLayoutAlign="end start">
            <button mat-button mat-icon-button color="link" mat-dialog-close><mat-icon>close</mat-icon></button>
        </div>
    </div>

</ng-template>

