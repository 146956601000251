import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'choose-picture',
    templateUrl: './choose-picture.component.html',
    styleUrls: ['./choose-picture.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChoosePictureComponent implements OnInit {
    imageError: string;
    isImageSaved: boolean;
    public picture: string;

    @ViewChild('choosePictureInput') choosePictureInput: ElementRef;

    @Input() placeholder: string = 'assets/img/garbage_picture_with_button.png';
    @Input() label: string = 'Zrób zdjęcie';
    @Output() change = new EventEmitter();

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {}

    public select() {
        this.choosePictureInput.nativeElement.click();
    }

    fileChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
            const max_height = 15200;
            const max_width = 25600;

            if (fileInput.target.files[0].size > max_size) {
                this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';

                return false;
            }

            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = (rs) => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    if (img_height > max_height && img_width > max_width) {
                        this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';

                        this.change.emit(null);
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.picture = imgBase64Path;
                        this.isImageSaved = true;
                        this.cdr.detectChanges();
                        this.change.emit(this.picture);
                    }
                };
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

    removeImage() {
        this.picture = null;
        this.isImageSaved = false;
    }
}
