import Dexie, { PromiseExtended, Table } from 'dexie';

const OfflineMissingTileUrl = '/assets/img/offline_tile.png';

export class MapsOfflineDatabaseTile {
    id: string;
    urlTemplate: string;
    when: number;
    url: string;
    x: number;
    y: number;
    z: number;
    data: Blob;
}

export class MapsOfflineDatabase extends Dexie {
    tiles!: Table<MapsOfflineDatabaseTile, string>;

    offlineTileBlog: Promise<Blob>;

    constructor() {
        super('maps-offline');

        this.version(1).stores({
            tiles: '++id, z'
        });

        this.tiles.mapToClass(MapsOfflineDatabaseTile);
        fetch(OfflineMissingTileUrl).then((response) => {
            this.offlineTileBlog = response.blob();
        });
    }

    public storeTile(tile: MapsOfflineDatabaseTile, blob: Promise<Blob>): Promise<string> {
        return blob.then((data) => {
            tile.data = data;
            return this.tiles.put(tile, tile.id);
        });
    }

    public tileExists(id: string): PromiseExtended<MapsOfflineDatabaseTile> {
        return this.tiles.get(id);
    }

    public getTile(id: string): PromiseExtended {
        return this.tiles.get(id).then((item) => {
            if (item == null && this.offlineTileBlog) {
                return this.offlineTileBlog;
            } else {
                return item.data;
            }
        });
    }
}
