<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content style="overflow: hidden;" fxLayoutAlign="start start">
    <div [innerHtml]="description">
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button *ngIf="buttons.includes(InfoDialogComponent.OK)" (click)="clickClose(InfoDialogComponent.OK)">OK</button>
    <button mat-flat-button *ngIf="buttons.includes(InfoDialogComponent.NO)" (click)="clickClose(InfoDialogComponent.NO)">NIE</button>
    <button mat-flat-button *ngIf="buttons.includes(InfoDialogComponent.YES)" (click)="clickClose(InfoDialogComponent.YES)">TAK</button>
</mat-dialog-actions>