import { Action, createReducer, on } from '@ngrx/store';
import {
    actionNetworkFastDetected,
    actionNetworkPingFailure,
    actionNetworkPingSuccess,
    actionNetworkSlowDetected,
    actionNetworkStartMonitoring,
    actionNetworkStartOffline,
    actionNetworkStopOffline
} from './network.actions';
import { NetworkState } from './network.state';

export const networkPingDelay = 2500;
export const networkSlowConnectionTreshold = 3500;
export const networkFailedPingsTreshold = 6;
export const networkSuccessPingsTreshold = 3;

export const initialState: NetworkState = {
    isOffline: false,
    slowNetwork: false,
    lastPingTime: 0,
    avgPingTime: 0,
    monitoringActive: false,
    pingsFailed: 0,
    pingsSuccess: 0
};

function getAvgTime(currentAvg: number, currentTime: number): number {
    if (currentAvg > 0) {
        return Math.round((currentAvg + currentTime) / 2);
    } else return currentTime;
}

const reducer = createReducer(
    initialState,
    on(actionNetworkStartMonitoring, (state) => ({ ...state, monitoringActive: true })),
    on(actionNetworkStartOffline, (state) => ({ ...state, isOffline: true, pingsSuccess: 0, avgPingTime: 0, lastPingTime: 0 })),
    on(actionNetworkStopOffline, (state) => ({ ...state, isOffline: false })),
    on(actionNetworkSlowDetected, (state) => ({ ...state, slowNetwork: true, pingsSuccess: 0 })),
    on(actionNetworkFastDetected, (state) => ({ ...state, slowNetwork: false })),
    on(actionNetworkPingFailure, (state) => ({ ...state, pingsFailed: state.pingsFailed + 1, pingsSuccess: 0, lastPingTime: 0 })),
    on(actionNetworkPingSuccess, (state, { time }) => ({
        ...state,
        pingsFailed: 0,
        pingsSuccess: state.pingsSuccess + 1,
        lastPingTime: Math.round(time),
        avgPingTime: getAvgTime(state.avgPingTime, time)
    }))
);

export function networkReducer(state: NetworkState | undefined, action: Action): NetworkState {
    return reducer(state, action);
}
