import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions, OnInitEffects, act } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, delay, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ActivityItem } from '../../../common/models/activity-item.model';
import { MainApiService } from '../../../common/services/main-api.service';
import { actionCommonSetPlayerLocation } from '../../../common/store/common.actions';
import { authGetUserSuccess } from '../../../core/auth/auth.actions';
import { selectIsAuthenticated } from '../../../core/core.module';
import { selectNetworkState } from '../../../core/core.state';
import { actionCoreInitCompleted, actionCoreIsLoading } from '../../../core/init/init.actions';
import { actionNetworkFastDetected, actionNetworkStopOffline } from '../../../core/network/network.actions';
import { selectAreasUserInside } from '../../areas/store/areas.selectors';
import { actionDashboardShowGreetings } from '../../dashboard/store/dashboard.actions';
import { ActivitiesService } from '../activities.service';
import { selectActivitiesState } from '../activities.state';
import {
    actionActivitiesCreate,
    actionActivitiesCreateSuccess,
    actionActivitiesCreateFailure,
    actionActivitiesItemCreate,
    actionActivitiesItemCreateSuccess,
    actionActivitiesItemCreateFailure,
    actionActivitiesGetCurrent,
    actionActivitiesGetCurrentFailure,
    actionActivitiesGetCurrentSuccess,
    actionActivitiesFinish,
    actionActivitiesFinishFailure,
    actionActivitiesFinishSuccess,
    actionActivitiesActivityUpload,
    actionActivitiesActivityUploadSuccess,
    actionActivitiesActivityUploadFailure,
    actionActivitiesActivityItemUpload,
    actionActivitiesActivityItemUploadSuccess,
    actionActivitiesActivityItemUploadFailure,
    actionActivitiesSyncStart,
    actionActivitiesDownload,
    actionActivitiesDownloadSuccess,
    actionActivitiesDownloadFailure,
    actionActivitiesDirtyChange,
    actionActivitiesNotificationUpload,
    actionActivitiesNotificationUploadSuccess,
    actionActivitiesNotificationUploadFailure,
    actionActivitiesNotificationCreate,
    actionActivitiesNotificationCreateSuccess,
    actionActivitiesNotificationCreateFailure
} from './activities.actions';
import { selectActivitiesCurrent, selectActivitiesHasLocalData, selectActivitiesLastPositionSaveTime } from './activities.selectors';

@Injectable()
export class ActivitiesEffects {
    constructor(
        private actions$: Actions,
        private service: ActivitiesService,
        private router: Router,
        private store: Store,
        private apiService: MainApiService
    ) {}

    init = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCoreInitCompleted),
            switchMap(() => [actionActivitiesGetCurrent(), actionActivitiesDownload()])
        )
    );

    createActivity = createEffect(() =>
        this.actions$.pipe(
            ofType(actionActivitiesCreate),
            withLatestFrom(this.store.pipe(select(selectAreasUserInside))),
            switchMap(([action, areas]) =>
                this.service.createActivity(action.activity, action.startItem, areas).pipe(
                    map((activity) => {
                        return actionActivitiesCreateSuccess({ activity: activity, startItem: action.startItem });
                    }),
                    catchError((error) => of(actionActivitiesCreateFailure({ error: error })))
                )
            )
        )
    );
    createActivityItem = createEffect(() =>
        this.actions$.pipe(
            ofType(actionActivitiesItemCreate),
            withLatestFrom(this.store.pipe(select(selectActivitiesCurrent))),
            switchMap(([action, current]) =>
                this.service.createActivityItem(current, action.item).pipe(
                    map((activity) => {
                        return actionActivitiesItemCreateSuccess({ activity: activity, item: action.item });
                    }),
                    catchError((error) => of(actionActivitiesItemCreateFailure({ error: error })))
                )
            )
        )
    );

    finishActivity = createEffect(() =>
        this.actions$.pipe(
            ofType(actionActivitiesFinish),
            withLatestFrom(this.store.pipe(select(selectActivitiesCurrent))),
            switchMap(([action, current]) =>
                this.service.finishActivity(current, action.finishItem).pipe(
                    map((activity) => {
                        return actionActivitiesFinishSuccess({ activity: activity, finishItem: action.finishItem });
                    }),
                    catchError((error) => of(actionActivitiesFinishFailure({ error: error })))
                )
            )
        )
    );

    createNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionActivitiesNotificationCreate),
            withLatestFrom(this.store.pipe(select(selectAreasUserInside))),
            switchMap(([action, areas]) =>
                this.service.createNotification(action.notification, areas).pipe(
                    map((notification) => {
                        return actionActivitiesNotificationCreateSuccess({ notification: notification });
                    }),
                    catchError((error) => of(actionActivitiesNotificationCreateFailure({ error: error })))
                )
            )
        )
    );

    downloadActivities = createEffect(() =>
        this.actions$.pipe(
            ofType(actionActivitiesDownload, actionActivitiesDirtyChange),
            withLatestFrom(this.store.pipe(select(selectIsAuthenticated)), this.store.pipe(select(selectActivitiesHasLocalData))),
            filter(([action, isAuthenticated, hasLocalData]) => isAuthenticated && !hasLocalData),
            switchMap((action) =>
                this.apiService.activitiesDownload().pipe(
                    map((result) => {
                        return actionActivitiesDownloadSuccess({ items: result });
                    }),
                    catchError((error) => of(actionActivitiesDownloadFailure({ error: error })))
                )
            )
        )
    );

    activityItemPositionCreate = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCommonSetPlayerLocation),
            withLatestFrom(this.store.pipe(select(selectActivitiesLastPositionSaveTime)), this.store.pipe(select(selectActivitiesCurrent))),
            filter(([action, lastSave, currentActivity]) => currentActivity != null && Math.abs(Date.now() - lastSave) / 1000 / 60 > 1),
            map(([action, lastSave, currentActivity]) =>
                actionActivitiesItemCreate({
                    item: ActivityItem.Position(action.location, currentActivity.id)
                })
            )
        )
    );

    showGreetingsAfterFisnishActivity = createEffect(() =>
        this.actions$.pipe(
            ofType(actionActivitiesFinishSuccess),
            delay(1000),
            map((action) => actionDashboardShowGreetings({ name: '1', activity: action.activity }))
        )
    );

    // ////////////
    // ///////////
    // //// ONLINE
    uploadActivity = createEffect(() =>
        this.actions$.pipe(
            ofType(actionActivitiesActivityUpload),
            withLatestFrom(this.store.pipe(select(selectIsAuthenticated))),
            filter(([action, isAuthenticated]) => isAuthenticated),
            switchMap(([action, isAuthenticated]) =>
                this.apiService.activityUpload(action.activity).pipe(
                    map((result) => {
                        return actionActivitiesActivityUploadSuccess({ activity: action.activity, server_id: result });
                    }),
                    catchError((error) => of(actionActivitiesActivityUploadFailure({ error: error })))
                )
            )
        )
    );

    uploadActivityItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionActivitiesActivityItemUpload),
            withLatestFrom(this.store.pipe(select(selectIsAuthenticated))),
            filter(([action, isAuthenticated]) => isAuthenticated),
            switchMap(([action, isAuthenticated]) =>
                this.apiService.activityItemUpload(action.item).pipe(
                    map((result) => {
                        return actionActivitiesActivityItemUploadSuccess({ activity: action.activity, item: action.item, server_id: result });
                    }),
                    catchError((error) => of(actionActivitiesActivityItemUploadFailure({ error: error })))
                )
            )
        )
    );

    uploadNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionActivitiesNotificationUpload),
            withLatestFrom(this.store.pipe(select(selectIsAuthenticated))),
            filter(([action, isAuthenticated]) => isAuthenticated),
            switchMap(([action, isAuthenticated]) =>
                this.apiService.notificationUpload(action.notification).pipe(
                    map((result) => {
                        return actionActivitiesNotificationUploadSuccess({ notification: action.notification, server_id: result });
                    }),
                    catchError((error) => of(actionActivitiesNotificationUploadFailure({ error: error })))
                )
            )
        )
    );

    // /////////
    // /// SYNC
    // ///////////
    initSync$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                actionCoreInitCompleted,
                actionActivitiesCreateSuccess,
                actionActivitiesActivityUploadSuccess,
                actionActivitiesActivityItemUploadSuccess,
                actionActivitiesItemCreateSuccess,
                actionNetworkStopOffline,
                actionNetworkFastDetected,
                actionActivitiesFinishSuccess,
                actionActivitiesNotificationCreateSuccess,
                authGetUserSuccess
            ),
            withLatestFrom(
                combineLatest([
                    this.store.pipe(select(selectNetworkState)),
                    this.store.pipe(select(selectActivitiesState)),
                    this.store.pipe(select(selectIsAuthenticated))
                ])
            ),
            filter(([action, [networkState, activitiesState, isAuthenticated]]) => !networkState.isOffline),
            filter(([action, [networkState, activitiesState, isAuthenticated]]) => activitiesState.hasLocalData),
            filter(([action, [networkState, activitiesState, isAuthenticated]]) => !networkState.slowNetwork),
            filter(([action, [networkState, activitiesState, isAuthenticated]]) => isAuthenticated),
            map(() => actionActivitiesSyncStart())
        )
    );

    syncLoop$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionActivitiesSyncStart),
                withLatestFrom(combineLatest([this.store.pipe(select(selectNetworkState)), this.store.pipe(select(selectActivitiesState))])),
                filter(([action, [networkState, activitiesState]]) => !networkState.isOffline),
                filter(([action, [networkState, activitiesState]]) => !networkState.slowNetwork),
                tap(([action, [networkState, activitiesState]]) => {
                    this.service.syncActivitiesState(activitiesState);
                })
            ),
        { dispatch: false }
    );
}
