export class GpsLocation {
    public lat: number;
    public lng: number;
    alt: number;
    accuracy: number;

    constructor(lat: number, lng: number, alt: number, accuracy: number) {
        this.lat = lat;
        this.lng = lng;
        this.alt = alt;
        this.accuracy = accuracy;
    }
}
