import { Action, createReducer, on } from '@ngrx/store';
import { DropzonesState } from '../dropzones.state';
import { actionDropzonesSearchSuccess, actionDropzonesSetMapMarkers, actionDropzonesSetUserNearDropzones } from './dropzones.actions';

export const initialState: DropzonesState = {
    items: [],
    mapMarkers: [],
    userNear: []
};

const reducer = createReducer(
    initialState,
    on(actionDropzonesSearchSuccess, (state, { dropzones }) => {
        return {
            ...state,
            items: dropzones
        };
    }),

    on(actionDropzonesSetMapMarkers, (state, { markers }) => ({
        ...state,
        mapMarkers: markers
    })),

    on(actionDropzonesSetUserNearDropzones, (state, { dropzones }) => ({
        ...state,
        userNear: dropzones
    }))
);

export function dropzonesReducer(state: DropzonesState | undefined, action: Action) {
    return reducer(state, action);
}
