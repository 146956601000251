import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MainApiService } from '../../../common/services/main-api.service';
import { MapUtilsService } from '../../../common/services/map-utils.service';
import { selectIsAuthenticated } from '../../../core/core.module';
import { actionCoreInitCompleted, actionCoreIsLoading } from '../../../core/init/init.actions';
import {
    actionDashboardGetNews,
    actionDashboardGetNewsFailure,
    actionDashboardGetNewsSuccess,
    actionDashboardGetUserStats,
    actionDashboardGetUserStatsFailure,
    actionDashboardGetUserStatsSuccess
} from './dashboard.actions';

@Injectable()
export class DashboardEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private apiService: MainApiService,
        private mapUtilsService: MapUtilsService
    ) {}

    init = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCoreInitCompleted),
            switchMap(() => [actionDashboardGetNews(), actionDashboardGetUserStats()])
        )
    );

    getNews = createEffect(() =>
        this.actions$.pipe(
            ofType(actionDashboardGetNews),
            switchMap((action) =>
                this.apiService.getNews().pipe(
                    map((news) => {
                        return actionDashboardGetNewsSuccess({ news: news });
                    }),
                    catchError((error) => of(actionDashboardGetNewsFailure({ error: error })))
                )
            )
        )
    );

    getStats = createEffect(() =>
        this.actions$.pipe(
            ofType(actionDashboardGetUserStats),
            withLatestFrom(this.store.pipe(select(selectIsAuthenticated))),
            filter(([action, isAuthenticated]) => isAuthenticated),
            switchMap(([action, isAuthenticated]) =>
                this.apiService.getUserStats().pipe(
                    map((stats) => {
                        return actionDashboardGetUserStatsSuccess({ stats: stats });
                    }),
                    catchError((error) => of(actionDashboardGetUserStatsFailure({ error: error })))
                )
            )
        )
    );
}
