import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityItem } from '../models/activity-item.model';
import { Activity } from '../models/activity.model';
import { Dropzone } from '../models/dropzone.model';
import { Area } from '../models/area.model';
import { DataApiService } from './data-api-service';
import { DashboardViewModel } from '../../features/dashboard/models/dashboard-view.model';
import { GpsLocation } from '../models/gps-location.model';
import { News } from '../models/news.model';
import { UserStatsView } from '../models/user-stats-view.model';
import { User } from '../../core/auth/auth.models';
import { Notification } from '../models/notification.model';
import { AppSettings } from '../models/app-settings.model';
import { Partner } from '../models/partner.model';
import { Coupon } from '../models/coupon.model';

@Injectable()
export class MainApiService extends DataApiService {
    pingApi(): Observable<number> {
        return this.get(`/network/ping`);
    }

    getAppSettings(): Observable<AppSettings> {
        return this.get(`/app/settings`);
    }

    getDashboardInfo(): Observable<DashboardViewModel> {
        return this.get(`/dashboard/info`);
    }

    getDropzones(): Observable<Dropzone[]> {
        return this.get(`/dropzones/search`);
    }
    createDropzone(dropzone: Dropzone, picture: string, area: Area): Observable<Dropzone> {
        let area_id = 0;
        if (area) area_id = area.id;
        return this.post(`/dropzones/create`, { dropzone: dropzone, image1: picture, area_id: area_id });
    }

    getAreas(query: string): Observable<Area[]> {
        if (query != null) return this.get(`/areas/search/${query}`);
        else return this.get(`/areas/search`);
    }
    getArea(id: number, withRanking: boolean): Observable<Area> {
        return this.post(`/areas/get`, { id: id, with_ranking: withRanking });
    }
    getAreaRanking(area_id: number): Observable<[]> {
        return this.get(`/areas/ranking/${area_id}`);
    }
    toggleAreaFav(id: number): Observable<number[]> {
        return this.get(`/areas/toggle_fav/${id}`);
    }

    activitiesDownload(): Observable<any> {
        return this.get(`/activities/search`);
    }

    getActivityCurrent(): Observable<Activity> {
        return this.get(`/activities/current`);
    }

    activityUpload(activity: Activity): Observable<number> {
        let newActivity: Activity = Object.assign(new Activity(), activity);
        newActivity.items = [];
        return this.post(`/activities/upload`, { activity: newActivity });
    }
    activityItemUpload(item: ActivityItem): Observable<number> {
        return this.post(`/activities/item/upload`, { item: item });
    }

    notificationUpload(notification: Notification): Observable<number> {
        return this.post(`/activities/notification/upload`, { notification: notification });
    }

    getNews(): Observable<News[]> {
        return this.get(`/news/search`);
    }

    getUserStats(): Observable<UserStatsView> {
        return this.get(`/dashboard/user_stats`);
    }

    searchPartners(): Observable<Partner[]> {
        return this.post(`/partners/search`, {});
    }
    getPartner(id: number, with_ranking: boolean): Observable<Partner> {
        return this.post(`/partners/get`, { id: id, with_ranking: with_ranking });
    }

    getCoupons(): Observable<Coupon[]> {
        return this.get(`/coupons/list`);
    }

    getUserCoupons(): Observable<Coupon[]> {
        return this.get(`/coupons/user/list`);
    }

    buyCoupon(coupon: Coupon): Observable<boolean> {
        return this.post(`/coupons/buy`, { coupon: coupon });
    }
}
