import { createAction, props } from '@ngrx/store';
import { Area } from '../../../common/models/area.model';
import { GpsLocation } from '../../../common/models/gps-location.model';

export const actionAreasGet = createAction('[AREAS] Get Areas', props<{ query: string }>());
export const actionAreasGetSuccess = createAction('[AREAS] Get Areas Success', props<{ areas: Area[] }>());
export const actionAreasGetFailure = createAction('[AREAS] Get Areas Failure', props<{ error: string }>());

export const actionAreasGetArea = createAction('[AREAS] Get Area', props<{ id: number; withRanking: boolean }>());
export const actionAreasGetAreaSuccess = createAction('[AREAS] Get Area Success', props<{ area: Area }>());
export const actionAreasGetAreaFailure = createAction('[AREAS] Get Area Failure', props<{ error: string }>());

export const actionAreasSetUserInsideAreas = createAction('[AREAS] Set User Inside Areas', props<{ areas: Area[] }>());

export const actionAreasSetByDistance = createAction('[AREAS] Set Areas by Distnace', props<{ areas: Area[] }>());
export const actionAreasDeselectArea = createAction('[AREAS] Deselect Area');

export const actionAreasMapsDownloadAdd = createAction('[AREAS] Maps Download Add', props<{ area: Area }>());

export const actionAreasMapsDownloadStart = createAction('[AREAS] Maps Download Start', props<{ total: number; left: number }>());
export const actionAreasMapsDownloadStop = createAction('[AREAS] Maps Download Stop');
export const actionAreasMapsDownloadProgress = createAction('[AREAS] Maps Download Progress', props<{ total: number; left: number }>());

export const actionAreasToggleFav = createAction('[AREAS] Toggle Fav', props<{ id: number }>());
export const actionAreasToggleFavSuccess = createAction('[AREAS] Toggle Fav Success', props<{ favs: number[] }>());
export const actionAreasToggleFavFailure = createAction('[AREAS] Toggle Fav Failure', props<{ error: string }>());

export const actionAreasGetRanking = createAction('[AREAS] Get Ranking', props<{ area_id: number }>());
export const actionAreasGetRankingSuccess = createAction('[AREAS] Get Ranking Success', props<{ ranking: [] }>());
export const actionAreasGetRankingFailure = createAction('[AREAS] Get Ranking Failure', props<{ error: string }>());
