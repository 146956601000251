import { createFeatureSelector } from '@ngrx/store';
import { ActivityItem } from '../../common/models/activity-item.model';
import { Activity } from '../../common/models/activity.model';
import { Notification } from '../../common/models/notification.model';
import { AppState } from '../../core/core.state';

export const selectActivitiesState = createFeatureSelector<State, ActivitiesState>('activities');

export interface ActivitiesState {
    items: Activity[];
    lastPositionSaveTime: number;
    isUserInsideCurrentArea: boolean;
    syncActive: boolean;
    hasLocalData: boolean;
    notifications: Notification[];
}

export interface State extends AppState {
    activities: ActivitiesState;
}
