import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'info-baner',
    templateUrl: './info-baner.component.html',
    styleUrls: ['./info-baner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBanerComponent implements OnInit {
    @Input() icon: string;
    @Input() text: string;
    @Input() link: string = null;
    @Input() color: string;

    public class: string = 'info-baner';

    constructor() {}

    ngOnInit(): void {
        if (this.color) this.class += ' ' + this.color;
    }
}
