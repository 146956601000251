import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Area } from '../../../common/models/area.model';
import { MainApiService } from '../../../common/services/main-api.service';
import { MapUtilsService } from '../../../common/services/map-utils.service';
import { actionCommonSetPlayerLocation } from '../../../common/store/common.actions';
import { selectCommonUserLocation } from '../../../common/store/common.selectors';
import { actionCoreInitCompleted, actionCoreIsLoading } from '../../../core/init/init.actions';
import {
    actionActivitiesGetCurrentSuccess,
    actionActivitiesUpdateUserInsideCurrentArea,
    actionActivitiesUpdateUserInsideCurrentAreaSuccess
} from '../../activities/store/activities.actions';
import { selectActivitiesCurrent } from '../../activities/store/activities.selectors';
import { MapsOfflineService } from '../../maps/maps-offline.service';
import { selectAreasState } from '../areas.state';
import {
    actionAreasGet,
    actionAreasGetArea,
    actionAreasGetAreaFailure,
    actionAreasGetAreaSuccess,
    actionAreasGetFailure,
    actionAreasGetRanking,
    actionAreasGetRankingFailure,
    actionAreasGetRankingSuccess,
    actionAreasGetSuccess,
    actionAreasMapsDownloadAdd,
    actionAreasMapsDownloadProgress,
    actionAreasMapsDownloadStart,
    actionAreasMapsDownloadStop,
    actionAreasSetByDistance,
    actionAreasSetUserInsideAreas,
    actionAreasToggleFav,
    actionAreasToggleFavFailure,
    actionAreasToggleFavSuccess
} from './areas.actions';
import { selectAreas, selectAreasUserInside } from './areas.selectors';

@Injectable()
export class AreasEffects {
    constructor(
        private actions$: Actions,
        private router: Router,
        private store: Store,
        private apiService: MainApiService,
        private mapUtilsService: MapUtilsService,
        private mapsOffline: MapsOfflineService
    ) {}

    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCoreInitCompleted, actionAreasGetArea),
            map((action) => actionAreasGet({ query: null }))
        )
    );

    checkAreasUserIn = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCommonSetPlayerLocation),
            withLatestFrom(this.store.pipe(select(selectAreas))),
            map(([action, areas]) => {
                const areasUserIn = this.mapUtilsService.getAreasUserIn(areas, action.location);
                return actionAreasSetUserInsideAreas({ areas: areasUserIn });
            })
        )
    );

    setAreasByDistance = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCommonSetPlayerLocation, actionAreasGetAreaSuccess),
            withLatestFrom(this.store.pipe(select(selectAreas)), this.store.pipe(select(selectCommonUserLocation))),
            map(([action, areas, location]) => {
                const areasByDistance = this.mapUtilsService.sortAreasByDistance(areas, location);
                return actionAreasSetByDistance({ areas: areasByDistance });
            })
        )
    );

    updateUserIsCurrentArea = createEffect(() =>
        this.actions$.pipe(
            ofType(actionActivitiesUpdateUserInsideCurrentArea, actionActivitiesGetCurrentSuccess, actionAreasSetUserInsideAreas),
            withLatestFrom(this.store.pipe(select(selectActivitiesCurrent)), this.store.pipe(select(selectAreasUserInside))),
            filter(([action, current, areas]) => current != null && areas != null),
            map(([action, current, areas]) => {
                const isIn = areas.filter((item) => item.id == current.area_id).length > 0;
                return actionActivitiesUpdateUserInsideCurrentAreaSuccess({ value: isIn });
            })
        )
    );

    checkAreasUserInAfterAreasGet = createEffect(() =>
        this.actions$.pipe(
            ofType(actionAreasGetSuccess),
            withLatestFrom(this.store.pipe(select(selectCommonUserLocation))),
            map(([action, location]) => {
                const areasUserIn = this.mapUtilsService.getAreasUserIn(action.areas, location);
                return actionAreasSetUserInsideAreas({ areas: areasUserIn });
            })
        )
    );

    getAreas = createEffect(() =>
        this.actions$.pipe(
            ofType(actionAreasGet),
            switchMap((action) =>
                this.apiService.getAreas(action.query).pipe(
                    map((areas) => {
                        return actionAreasGetSuccess({ areas: areas });
                    }),
                    catchError((error) => of(actionAreasGetFailure({ error: error })))
                )
            )
        )
    );

    getAreasToCache = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actionAreasGetSuccess),
                tap((action) => {
                    for (let area of action.areas) {
                        this.apiService.getArea(area.id, false);
                    }
                })
            ),
        { dispatch: false }
    );

    getArea = createEffect(() =>
        this.actions$.pipe(
            ofType(actionAreasGetArea),
            switchMap((action) =>
                this.apiService.getArea(action.id, action.withRanking).pipe(
                    map((area) => {
                        return actionAreasGetAreaSuccess({ area: area });
                    }),
                    catchError((error) => of(actionAreasGetAreaFailure({ error: error })))
                )
            )
        )
    );

    getAreaRanking = createEffect(() =>
        this.actions$.pipe(
            ofType(actionAreasGetRanking),
            switchMap((action) =>
                this.apiService.getAreaRanking(action.area_id).pipe(
                    map((ranking) => {
                        return actionAreasGetRankingSuccess({ ranking: ranking });
                    }),
                    catchError((error) => of(actionAreasGetRankingFailure({ error: error })))
                )
            )
        )
    );

    toggleAreaFav$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actionAreasToggleFav),
            switchMap((action) =>
                this.apiService.toggleAreaFav(action.id).pipe(
                    map((favs) => {
                        return actionAreasToggleFavSuccess({ favs: favs });
                    }),
                    catchError((error) => of(actionAreasToggleFavFailure({ error: error })))
                )
            )
        )
    );

    /// MAPS
    /// UPLOAD
    checkWhenStartDownload = createEffect(() =>
        this.actions$.pipe(
            ofType(actionAreasMapsDownloadAdd),
            withLatestFrom(this.store.pipe(select(selectAreasState))),
            filter(([action, state]) => !state.mapsDownloadInProgress),
            switchMap(([action, state]) =>
                this.mapsOffline.addAreasToDownload([action.area]).pipe(
                    map((total) => {
                        return actionAreasMapsDownloadStart({ total: total, left: total });
                    }),
                    catchError((error) => of(actionAreasMapsDownloadStop()))
                )
            )
        )
    );

    progressMapsDownload = createEffect(() =>
        this.actions$.pipe(
            ofType(actionAreasMapsDownloadStart, actionAreasMapsDownloadProgress),
            filter((action) => action.left > 0),
            switchMap((action) =>
                this.mapsOffline.downloadNextTile().pipe(
                    map((result) => {
                        return actionAreasMapsDownloadProgress({ left: result, total: action.total });
                    }),
                    catchError((error) => of(actionAreasMapsDownloadStop()))
                )
            )
        )
    );
}
