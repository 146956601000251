import { TranslateService } from '@ngx-translate/core';
import { ActivityItem } from './activity-item.model';
import { Area } from './area.model';
import * as uuid from 'uuid';

export class Activity {
    public id?: number;

    public start_time: Date;
    public end_time?: Date;

    public area_id: number;
    public area: Area;
    public status: ActivityStatus = ActivityStatus.CURRENT;

    public distance: number = 0;
    public last_lng: number = 0;
    public last_lat: number = 0;
    public last_add_garbage_lng: number = 0;
    public last_add_garbage_lat: number = 0;

    public share_code: string = Math.random().toString(36).slice(2, 7);

    public cumulated_coins: number = 0;
    public accepted_coins: number = 0;

    public min_distance_valid: boolean = false;
    public home_finish_valid: boolean = false;
    public last_add_distance: number = 0;

    public activity_type: ActivityType = ActivityType.WALKING;

    // counts
    public garbage_count: number = 0;
    public bag_count: number = 0;
    public dropzone_count: number = 0;
    public picture_count: number = 0;

    public items: ActivityItem[] = [];

    public dirty: boolean = true;
    public guid: string = uuid.v4();

    constructor() {}

    public static GetTitle(activityType: ActivityType, trans: TranslateService): string {
        return trans.instant('ACTIVITY_TYPE_DESC.' + activityType);
    }

    public static GetTypeIcon(activityType: ActivityType): string {
        switch (activityType) {
            case ActivityType.CYCLING:
                return 'assets/img/activities/cycling.png';
            case ActivityType.WALKING:
                return 'assets/img/activities/walking.png';
            case ActivityType.HIKING:
                return 'assets/img/activities/hiking.png';
            case ActivityType.RUNNING:
                return 'assets/img/activities/running.png';
        }
    }
}

export enum ActivityType {
    WALKING = 1,
    HIKING = 2,
    CYCLING = 3,
    RUNNING = 4
}

export enum ActivityStatus {
    CURRENT = 1,
    COMPLETED = 2,
    CLOSED = 3,
    VERIFICATION = 4,
    ACCEPTED = 5,
    ACCEPTED_PARTIALLY = 6,
    REJECTED = 7,
    NOT_FINISHED = 9
}
