import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { DataApiService } from './data-api-service';
import { v4 as uuid } from 'uuid';
import { LocalStorageService } from '../../core/core.module';

@Injectable()
export class AppCoreService {
    public STORAGE_CORE_INSTANCE_ID: string = 'instanceId';

    constructor(private localStorage: LocalStorageService) {}

    public getInstanceId(): Observable<string> {
        let currentId: string = this.localStorage.getItem(this.STORAGE_CORE_INSTANCE_ID);

        if (!currentId) {
            currentId = uuid();
            this.localStorage.setItem(this.STORAGE_CORE_INSTANCE_ID, currentId);
        }

        return of(currentId);
    }
}
