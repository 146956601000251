import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { actionCoreInit, actionCoreIsLoading } from '../init/init.actions';
import { LocalStorageService } from '../local-storage/local-storage.service';
import {
    authLogin,
    authLogout,
    authAnonymousLogin,
    authLoginSuccess,
    authLoginFailure,
    authInit,
    authRefreshTokenSuccess,
    authRefreshTokenFailure,
    authGetUserSuccess,
    authGetUserFailure,
    authInitCompleted,
    authRegister,
    authRegisterSuccess,
    authRegisterFailure,
    authUserUpdate,
    authUserUpdateSuccess,
    authUserUpdateFailure,
    authUserDelete,
    authUserDeleteSuccess,
    authUserDeleteFailure,
    authPasswordResetRequest,
    authPasswordResetRequestSuccess,
    authPasswordResetRequestFailure,
    authPasswordResetApply,
    authPasswordResetApplySuccess,
    authPasswordResetApplyFailure
} from './auth.actions';
import { User } from './auth.models';
import { AuthenticationService } from './authentication.service';

export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects {
    constructor(
        private store: Store,
        private actions$: Actions,
        private router: Router,
        private authService: AuthenticationService,
        private localStorageService: LocalStorageService
    ) {}

    authInit = createEffect(() =>
        this.actions$.pipe(
            ofType(actionCoreInit),
            map(() => authInit())
        )
    );

    tryRefreshToken = createEffect(() =>
        this.actions$.pipe(
            ofType(authInit),
            exhaustMap((action) =>
                this.authService.refreshToken().pipe(
                    map((result) => {
                        this.localStorageService.setItem(AUTH_KEY, {
                            isAuthenticated: true
                        });
                        return authRefreshTokenSuccess();
                    }),
                    catchError((error) => of(authRefreshTokenFailure()))
                )
            )
        )
    );

    getUser = createEffect(() =>
        this.actions$.pipe(
            ofType(authLoginSuccess, authRefreshTokenSuccess),
            exhaustMap((action) =>
                this.authService.getUser().pipe(
                    map((user) => {
                        return authGetUserSuccess({ user: user });
                    }),
                    catchError((error) => of(authGetUserFailure({ error: error.message })))
                )
            )
        )
    );

    updateUser = createEffect(() =>
        this.actions$.pipe(
            ofType(authUserUpdate),
            exhaustMap((action) =>
                this.authService.updateUserProfile(action.user, action.avatar).pipe(
                    map((user) => {
                        return authUserUpdateSuccess({ user: user });
                    }),
                    catchError((error) => of(authUserUpdateFailure({ error: error.message })))
                )
            )
        )
    );

    passwordResetRequest = createEffect(() =>
        this.actions$.pipe(
            ofType(authPasswordResetRequest),
            exhaustMap((action) =>
                this.authService.passwordResetRequest(action.email).pipe(
                    map((user) => {
                        return authPasswordResetRequestSuccess();
                    }),
                    catchError((error) => of(authPasswordResetRequestFailure({ error: error.message })))
                )
            )
        )
    );

    passwordResetApply = createEffect(() =>
        this.actions$.pipe(
            ofType(authPasswordResetApply),
            exhaustMap((action) =>
                this.authService.passwordResetApply(action.email, action.password, action.token).pipe(
                    map((user) => {
                        return authPasswordResetApplySuccess();
                    }),
                    catchError((error) => of(authPasswordResetApplyFailure({ error: error.message })))
                )
            )
        )
    );

    authComplete = createEffect(() =>
        this.actions$.pipe(
            ofType(authGetUserSuccess),
            map(() => authInitCompleted({ isAuthenticated: true }))
        )
    );

    authCheckOfflineTokenExpired = createEffect(() =>
        this.actions$.pipe(
            ofType(authRefreshTokenFailure),
            switchMap(() =>
                this.authService.tokenExpired().pipe(
                    map((expired) => {
                        if (expired) return authInitCompleted({ isAuthenticated: false });
                        else return authInitCompleted({ isAuthenticated: true });
                    })
                )
            )
        )
    );

    login = createEffect(() =>
        this.actions$.pipe(
            ofType(authLogin),
            exhaustMap((action) =>
                this.authService.emailLogin(action.email, action.password).pipe(
                    map((result) => {
                        this.authService.saveAccessData(result);
                        this.localStorageService.setItem(AUTH_KEY, {
                            isAuthenticated: true
                        });
                        return authLoginSuccess({ access_token: result.access_token, refresh_token: result.refresh_token });
                    }),
                    catchError((error) => of(authLoginFailure({ error: error })))
                )
            )
        )
    );

    register = createEffect(() =>
        this.actions$.pipe(
            ofType(authRegister),
            switchMap((action) =>
                this.authService.register(action.name, action.email, action.password, action.password, action.details).pipe(
                    map((result) => {
                        this.authService.saveAccessData(result);
                        this.localStorageService.setItem(AUTH_KEY, {
                            isAuthenticated: true
                        });
                        return authRegisterSuccess({ access_token: result.access_token, refresh_token: result.refresh_token });
                    }),
                    catchError((error) => of(authRegisterFailure({ error: error })))
                )
            )
        )
    );

    redirectAfterLogin$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(authLoginSuccess),
                tap(() => this.router.navigate(['/']))
            ),
        { dispatch: false }
    );

    logout = createEffect(
        () =>
            this.actions$.pipe(
                ofType(authLogout),
                tap(() => {
                    this.localStorageService.setItem(AUTH_KEY, {
                        isAuthenticated: false
                    });

                    this.authService.logout();
                })
            ),
        { dispatch: false }
    );

    deleteUser = createEffect(() =>
        this.actions$.pipe(
            ofType(authUserDelete),
            exhaustMap((action) =>
                this.authService.deleteUser().pipe(
                    map((user) => {
                        return authUserDeleteSuccess();
                    }),
                    catchError((error) => of(authUserDeleteFailure({ error: error.message })))
                )
            )
        )
    );

    logoutAfterDeleteUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authUserDeleteSuccess),
            map(() => authLogout())
        )
    );
}
