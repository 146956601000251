import { v4 as uuid } from 'uuid';
import { createAction, props } from '@ngrx/store';
import { Area } from '../models/area.model';
import { GpsLocation } from '../models/gps-location.model';
import { AppSettings } from '../models/app-settings.model';

export const actionCommonAppIsForeground = createAction('[COMMON] App Foreground');
export const actionCommonAppIsBackground = createAction('[COMMON] App Background');

export const actionCommonLocationPermissionPrompt = createAction('[COMMON] Location Permission Prompt');
export const actionCommonInitPlayerLocation = createAction('[COMMON] Init User Location');
export const actionCommonSetPlayerLocation = createAction('[COMMON] Set User Location', props<{ location: GpsLocation }>());
export const actionCommonLocationLowAccuracy = createAction('[COMMON] Location Low Accuracy', props<{ accuracy: number }>());

export const actionCommonBrowserClose = createAction('[COMMON] Browser Close', props<{ event: any }>());

export const actionCommonGpsError = createAction('[COMMON] GPS Error', props<{ error: string }>());

export const actionCommonAppSettingsGet = createAction('[COMMON] App Settings Get');
export const actionCommonAppSettingsGetSuccess = createAction('[COMMON] App Settings Get Success', props<{ settings: AppSettings }>());
export const actionCommonAppSettingsGetFailure = createAction('[COMMON] App Settings Get Failure', props<{ error: string }>());
