import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, map, switchMap, catchError, filter } from 'rxjs/operators';
import { AuthService } from 'ngx-auth';
import { throwError } from 'rxjs';

import { TokenStorageService } from './token-storage.service';
import { environment } from '../../../environments/environment';
import { User } from './auth.models';
import jwt_decode, { JwtPayload } from 'jwt-decode';

interface AccessData {
    access_token: string;
    token_type: string;
    expires_at: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements AuthService {
    apiUrl = `${environment.api_url}api`;

    private interruptedUrl: string;

    constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {}

    public isAuthorized(): Observable<boolean> {
        return this.tokenStorage.getAccessToken().pipe(map((token) => !!token));
    }

    public getAccessToken(): Observable<string> {
        return this.tokenStorage.getAccessToken();
    }

    public tokenExpired(): Observable<boolean> {
        return this.tokenStorage.getAccessToken().pipe(
            map((token: string) => {
                if (!token) return true;
                const tokenDecoded: JwtPayload = jwt_decode(token);
                const current_time = new Date().getTime() / 1000;
                if (current_time > tokenDecoded.exp) {
                    return true;
                }
                return false;
            })
        );
    }

    public refreshToken(): Observable<AccessData> {
        return this.tokenStorage.getRefreshToken().pipe(
            switchMap((refreshToken: string) => this.http.post(`${environment.api_url}api/auth/refresh`, { refresh_token: refreshToken })),
            tap((tokens: AccessData) => this.saveAccessData(tokens)),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public refreshShouldHappen(response: HttpErrorResponse): boolean {
        //return response.status === 401;
        return;
    }

    public verifyTokenRequest(url: string): boolean {
        return url.endsWith('/refresh');
    }

    public emailLogin(email: string, password: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/auth/login`, { email: email, password: password });
    }

    public register(display_name: string, email: string, password: string, password_confirmation: string, details: any[]): Observable<any> {
        return this.http.post(`${this.apiUrl}/auth/register`, {
            name: display_name,
            email: email,
            password: password,
            password_confirmation: password_confirmation,
            details: details
        });
    }

    public activation(token: string): Observable<any> {
        return this.http.get(`${this.apiUrl}/auth/signup/activate/${token}`);
    }

    public passwordResetRequest(email: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/auth/password_reset_request`, { email: email });
    }

    public passwordResetApply(email: string, new_password: string, token: string): Observable<any> {
        return this.http.post(`${this.apiUrl}/auth/password_reset_apply`, {
            email: email,
            password: new_password,
            password_confirmation: new_password,
            token: token
        });
    }

    public getUser(): Observable<User> {
        return this.http.get<User>(`${this.apiUrl}/auth/user`);
    }

    public facebookLogin(): Observable<any> {
        return of(null);
    }

    /**
     * Logout
     */
    public logout(): void {
        this.tokenStorage.clear();
        location.reload();
    }

    public saveAccessData(response: any): void {
        this.tokenStorage.setAccessToken(response.access_token).setRefreshToken(response.refresh_token);
    }

    public getInterruptedUrl(): string {
        return this.interruptedUrl;
    }

    public setInterruptedUrl(url: string): void {
        this.interruptedUrl = url;
    }

    public updateUserProfile(user: User, avatar: string): Observable<User> {
        return this.http.post<User>(`${this.apiUrl}/auth/user/update`, { user: user, avatar: avatar });
    }

    public deleteUser(): Observable<User> {
        return this.http.delete<User>(`${this.apiUrl}/auth/delete`);
    }
}
