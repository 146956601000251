import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivityItem, ActivityItemType } from '../../common/models/activity-item.model';
import { Activity, ActivityStatus } from '../../common/models/activity.model';
import { AppSettings } from '../../common/models/app-settings.model';
import { Area } from '../../common/models/area.model';
import { GpsLocation } from '../../common/models/gps-location.model';
import { Notification } from '../../common/models/notification.model';
import { MapUtilsService } from '../../common/services/map-utils.service';
import { selectCommonAppSettings } from '../../common/store/common.selectors';
import { MapsOfflineService } from '../maps/maps-offline.service';
import { ActivitiesState } from './activities.state';
import {
    actionActivitiesActivityItemUpload,
    actionActivitiesActivityUpload,
    actionActivitiesDirtyChange,
    actionActivitiesNotificationUpload
} from './store/activities.actions';

@Injectable({
    providedIn: 'root'
})
export class ActivitiesService {
    private settings: AppSettings;

    constructor(private store: Store, private mapUtils: MapUtilsService, private mapsOffline: MapsOfflineService) {
        this.store.pipe(select(selectCommonAppSettings)).subscribe((settings) => {
            this.settings = settings;
        });
    }

    private addItem(activity: Activity, item: ActivityItem): void {
        activity.garbage_count = +item.garbage_count;
        activity.bag_count = +item.bag_count;
        if (item.dropzone_id > 0) {
            activity.dropzone_count++;
        }
        if (item.image) {
            activity.picture_count++;
        }
        // distance
        if (item.lat > 0 && item.lng > 0 && activity.last_lat > 0 && activity.last_lng > 0) {
            const distance = this.mapUtils.getDistanceBetweendPoints(item.lng, item.lat, activity.last_lng, activity.last_lat);
            activity.distance = Number(activity.distance) + distance;
            // update last from garbage
            //activity.last_add_distance = Number(activity.last_add_distance) + distance;
            const distanceFromLastAdd = this.mapUtils.getDistanceBetweendPoints(
                item.lng,
                item.lat,
                activity.last_add_garbage_lng,
                activity.last_add_garbage_lat
            );
            activity.last_add_distance = distanceFromLastAdd; //Number(activity.last_add_distance) + distance;
        }
        // update last
        if (item.lat > 0 && item.lng > 0) {
            activity.last_lat = item.lat;
            activity.last_lng = item.lng;

            if (item.activity_item_type == ActivityItemType.ADDGARBAGE) {
                activity.last_add_garbage_lat = item.lat;
                activity.last_add_garbage_lng = item.lng;
                activity.last_add_distance = 0;
            }
            if (this.settings) {
                activity.home_finish_valid =
                    this.mapUtils.distanceToArea(activity.area, new GpsLocation(item.lat, item.lng, 0, 0)) > this.settings.min_home_finish_distance;
            }
        }
        if (this.settings) {
            activity.min_distance_valid = activity.distance >= this.settings.min_activity_distance;
        }
    }

    public createActivity(activity: Activity, startItem: ActivityItem, areas: Area[]): Observable<Activity> {
        activity = Object.assign({}, activity);

        let area = areas[0];

        activity.area_id = area.id;
        activity.area = area;
        activity.status = ActivityStatus.CURRENT;
        activity.start_time = new Date();

        this.addItem(activity, startItem);

        return of(activity);
    }

    public createNotification(notification: Notification, areas: Area[]): Observable<Notification> {
        notification = Object.assign({}, notification);
        let area = areas[0];
        notification.area_id = area.id;
        notification.area = area;

        return of(notification);
    }

    public createActivityItem(activity: Activity, item: ActivityItem): Observable<Activity> {
        activity = Object.assign({}, activity);
        this.addItem(activity, item);

        return of(activity);
    }

    public finishActivity(activity: Activity, finishItem: ActivityItem): Observable<Activity> {
        activity = Object.assign({}, activity);

        activity.end_time = new Date();
        activity.status = ActivityStatus.COMPLETED;

        this.addItem(activity, finishItem);

        return of(activity);
    }

    public syncActivitiesState(activitiesState: ActivitiesState): void {
        for (let activity of activitiesState.items) {
            if (activity.dirty) {
                this.store.dispatch(actionActivitiesActivityUpload({ activity: activity }));
                return;
            }
            if (activity.items)
                for (let item of activity.items) {
                    if (item.dirty) {
                        this.store.dispatch(actionActivitiesActivityItemUpload({ activity: activity, item: item }));
                        return;
                    }
                }
        }
        for (let notification of activitiesState.notifications) {
            if (notification.dirty) {
                this.store.dispatch(actionActivitiesNotificationUpload({ notification: notification }));
                return;
            }
        }
        // if there
        this.store.dispatch(actionActivitiesDirtyChange({ isDirty: false }));
    }
}
