import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'ios-install-prompt',
    templateUrl: './ios-install-prompt.component.html',
    styleUrls: ['./ios-install-prompt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IosInstallPromptComponent implements OnInit {
    constructor() {}

    public static Show(dialog: MatDialog): MatDialogRef<IosInstallPromptComponent> {
        const dialogRef = dialog.open(IosInstallPromptComponent, {
            maxWidth: '300px',
            maxHeight: '400px',
            height: '400px',
            width: '300px'
        });
        return dialogRef;
    }

    ngOnInit(): void {}
}
