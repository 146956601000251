import { createAction, props } from '@ngrx/store';
import { ActivityItem } from '../../../common/models/activity-item.model';
import { Activity } from '../../../common/models/activity.model';
import { Notification } from '../../../common/models/notification.model';

export const actionActivitiesDownload = createAction('[Activities] Download');
export const actionActivitiesDownloadSuccess = createAction('[Activities] Download Success', props<{ items: Activity[] }>());
export const actionActivitiesDownloadFailure = createAction('[Activities] Download Failure', props<{ error: string }>());

export const actionActivitiesCreate = createAction('[Activities] Create', props<{ activity: Activity; startItem: ActivityItem }>());
export const actionActivitiesCreateSuccess = createAction('[Activities] Create Success', props<{ activity: Activity; startItem: ActivityItem }>());
export const actionActivitiesCreateFailure = createAction('[Activities] Create Failure', props<{ error: string }>());

export const actionActivitiesFinish = createAction('[Activities] Finish', props<{ activity: Activity; finishItem: ActivityItem }>());
export const actionActivitiesFinishSuccess = createAction('[Activities] Finish Success', props<{ activity: Activity; finishItem: ActivityItem }>());
export const actionActivitiesFinishFailure = createAction('[Activities] Finish Failure', props<{ error: string }>());

export const actionActivitiesItemCreate = createAction('[Activities] Item Create', props<{ item: ActivityItem }>());
export const actionActivitiesItemCreateSuccess = createAction('[Activities] Item Create Success', props<{ activity: Activity; item: ActivityItem }>());
export const actionActivitiesItemCreateFailure = createAction('[Activities] Item Create Failure', props<{ error: string }>());

export const actionActivitiesSetCurrent = createAction('[Activities] Set Current', props<{ current: Activity }>());

export const actionActivitiesGetCurrent = createAction('[Activities] Get Current');
export const actionActivitiesGetCurrentSuccess = createAction('[Activities] Get Current Success', props<{ current: Activity }>());
export const actionActivitiesGetCurrentFailure = createAction('[Activities] Get Current Failure', props<{ error: string }>());

export const actionActivitiesUpdateUserInsideCurrentArea = createAction('[Activities] User Inside Current Area ');
export const actionActivitiesUpdateUserInsideCurrentAreaSuccess = createAction('[Activities] User Inside Current Area Success', props<{ value: boolean }>());

export const actionActivitiesSyncStart = createAction('[Activities] Sync Activities Start');
export const actionActivitiesDirtyChange = createAction('[Activities] Activities Dirty', props<{ isDirty: boolean }>());

export const actionActivitiesActivityUpload = createAction('[Activities] Upload Activity', props<{ activity: Activity }>());
export const actionActivitiesActivityUploadSuccess = createAction('[Activities] Upload Activity Success', props<{ activity: Activity; server_id: number }>());
export const actionActivitiesActivityUploadFailure = createAction('[Activities] Upload Activity Failure', props<{ error: string }>());

export const actionActivitiesActivityItemUpload = createAction('[Activities] Upload Item Activity', props<{ activity: Activity; item: ActivityItem }>());
export const actionActivitiesActivityItemUploadSuccess = createAction(
    '[Activities] Upload  Activity Item Success',
    props<{ activity: Activity; item: ActivityItem; server_id: number }>()
);
export const actionActivitiesActivityItemUploadFailure = createAction('[Activities] Upload Activity Item Failure', props<{ error: string }>());

export const actionActivitiesNotificationCreate = createAction('[Activities] Notification Create', props<{ notification: Notification }>());
export const actionActivitiesNotificationCreateSuccess = createAction('[Activities] Notification Create Success', props<{ notification: Notification }>());
export const actionActivitiesNotificationCreateFailure = createAction('[Activities] Notification Create Failure', props<{ error: string }>());

export const actionActivitiesNotificationUpload = createAction('[Activities] Notification Upload', props<{ notification: Notification }>());
export const actionActivitiesNotificationUploadSuccess = createAction(
    '[Activities] Notification Upload Success',
    props<{ notification: Notification; server_id: number }>()
);
export const actionActivitiesNotificationUploadFailure = createAction('[Activities] Notification Upload Failure', props<{ error: string }>());
