import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class TokenStorageService {
    /**
     * Get access token
     * @returns {Observable<string>}
     */
    public getAccessToken(): Observable<string> {
        const token: string = <string>localStorage.getItem('accessToken');
        return of(token);
    }

    /**
     * Get refresh token
     * @returns {Observable<string>}
     */
    public getRefreshToken(): Observable<string> {
        const token: string = <string>localStorage.getItem('refreshToken');
        return of(token);
    }

    /**
     * Set access token
     * @returns {TokenStorage}
     */
    public setAccessToken(token: string): TokenStorageService {
        localStorage.setItem('accessToken', token);

        return this;
    }

    /**
     * Set refresh token
     * @returns {TokenStorage}
     */
    public setRefreshToken(token: string): TokenStorageService {
        localStorage.setItem('refreshToken', token);

        return this;
    }

    /**
     * Remove tokens
     */
    public clear(): void {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }
}
